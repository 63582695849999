import React, { useEffect, useState } from 'react';
import { Space, Typography } from 'antd';
import { Input, Upload, message } from 'antd';
import { Button, Spin } from 'antd'
import {
    PlusOutlined, InboxOutlined, LoadingOutlined, PoweroffOutlined,
    AudioOutlined, DeleteOutlined, CloudDownloadOutlined, EyeOutlined
} from '@ant-design/icons';
import moment from 'moment';
import {
    uploadReportAttachment, submitClinicalReport, getReportDetail,
    deleteClinicalReport, deleteFileClinicalReport, downloadClinicalReportFile
} from '../../../apis/apis'
import useAudioTranscription, { recordingStatus, idleStatus, connectingStatus } from "../../../libs/speech2text";
import useChatLLM, { PROMPT_SR_TYPE, PROMPT_LAY_LANG_TYPE, PROMPT_CONCLUSION_TYPE } from "../../../libs/ai";
import TextEditor from './TextEditor'
import { Collapse, Popconfirm } from 'antd';
import lodash from 'lodash'
import clsx from 'classnames'
import { appConfig } from "../../../config/config";
import { getToken } from "../../../libs/jwt";
import {
    FileTextOutlined,     // For structured report
    ReadOutlined,         // For lay language
    SolutionOutlined      // For conclusion
} from '@ant-design/icons';

const { Dragger } = Upload;
const { Title } = Typography;

const study_report_note = 'study_report_note';
const transcript_report_note = 'transcript_report_note';
const ai_suggestion_note = 'ai_suggestion_note';

const uploadedStatus = 'uploaded';
const { TextArea } = Input;

const speechToTextStatus = {
    [recordingStatus]: <PoweroffOutlined />,
    [idleStatus]: <AudioOutlined />,
    [connectingStatus]: <LoadingOutlined />,
}

const groupNotesByDate = (transcriptNotes, aiNotes) => {
    const allNotes = [...transcriptNotes, ...aiNotes];

    // Create Set of unique timestamps
    const uniqueDates = new Set(
        allNotes.map(note => moment(note.created_at).format('DD/MM/YYYY HH:mm'))
    );

    // Convert to sorted array
    const sortedDates = [...uniqueDates].sort((a, b) =>
        moment(b, 'DD/MM/YYYY HH:mm').valueOf() - moment(a, 'DD/MM/YYYY HH:mm').valueOf()
    );

    // Group notes by sorted dates
    return sortedDates.reduce((groups, dateTime) => {
        groups[dateTime] = {
            [transcript_report_note]: allNotes
                .filter(note =>
                    moment(note.created_at).format('DD/MM/YYYY HH:mm') === dateTime &&
                    note.type === transcript_report_note
                )
                .map(note => {
                    return {
                        html: note.note,
                        id: note.id,
                    }
                }),
            [ai_suggestion_note]: allNotes
                .filter(note =>
                    moment(note.created_at).format('DD/MM/YYYY HH:mm') === dateTime &&
                    note.type === ai_suggestion_note
                )
                .map(note => {
                    return {
                        html: note.note,
                        id: note.id,
                    }
                }),
            timestamp: moment(dateTime, 'DD/MM/YYYY HH:mm').valueOf(),
        };
        return groups;
    }, {});
};

const ReportEditor = ({
    medicalReport,
    onReportDelete,
    onRecordStart,
    onRecordStop,
    recordStatus,
    onMedicalReportChange,
    onReportUpdate,
    isCreating,
    isTranscribing = false,
    onRequestTranscript,
}) => {
    return (
        <div>
            <div className='mt-3'>
                <div className='w-full flex justify-between items-center pb-2.5'>
                    {isCreating && <span>Your dictation</span>}
                    <div className='flex gap-2'>
                        {onRecordStart &&
                            <Button
                                onClick={recordStatus === idleStatus ? onRecordStart : onRecordStop}
                                shape='circle'
                                icon={speechToTextStatus[recordStatus]} />
                        }
                        {onReportDelete && <Popconfirm
                            title="Delete this report"
                            description="Are you sure to delete this report?"
                            okText="Yes"
                            cancelText="No"
                            okButtonProps={{ type: 'primary', danger: true }}
                            onConfirm={onReportDelete}
                        >
                            <Button shape='circle' icon={<DeleteOutlined />} />
                        </Popconfirm>}
                        {onReportUpdate &&
                            <Popconfirm
                                title="Update report"
                                description="Are you sure to update this report?"
                                okText="Yes"
                                cancelText="No"
                                okButtonProps={{ type: 'primary', danger: true }}
                                onConfirm={onReportUpdate}
                            >
                                <Button shape='circle' icon={<CloudDownloadOutlined />} />
                            </Popconfirm>}

                    </div>
                </div>
                <TextEditor
                    showEditor={isCreating || onReportUpdate}
                    value={medicalReport[transcript_report_note] || ''}
                    onChange={(e) => onMedicalReportChange(transcript_report_note, e)}
                />
            </div>
            {isCreating &&
                <div className='mt-3'>
                    <div className='mb-2.5'>
                        <span>Final Transcript</span>
                        {isTranscribing && <Spin size='small' className='ml-2.5' />}
                        <div className="flex gap-2 mt-2">
                            <Button
                                icon={<FileTextOutlined />}
                                onClick={() => onRequestTranscript(PROMPT_SR_TYPE)}
                            >
                                Transform to SR
                            </Button>
                            <Button
                                icon={<ReadOutlined />}
                                onClick={() => onRequestTranscript(PROMPT_LAY_LANG_TYPE)}
                            >
                                Transform to Lay Lang Report
                            </Button>
                            <Button
                                icon={<SolutionOutlined />}
                                onClick={() => onRequestTranscript(PROMPT_CONCLUSION_TYPE)}
                            >
                                Transform to Conclusion
                            </Button>
                        </div>
                    </div>
                    <TextEditor
                        showEditor={isCreating || onReportUpdate}
                        value={medicalReport[ai_suggestion_note] || ''}
                        onChange={(e) => onMedicalReportChange(ai_suggestion_note, e)}
                    />
                </div>}
        </div>
    );
};
const ClinicalReport = ({ studyId, studyLink, onClose, closable = true }) => {
    const [fileList, setFileList] = useState([]);
    const [showCreateReport, setShowCreateReport] = useState(false);
    const [fileListMarkRemoveOnServer, setFileListMarkRemoveOnServer] = useState([]);
    const [historyReports, setHistoryReports] = useState({});
    const {
        finalTranscript,
        setFinalTranscript,
        startRecording,
        status,
        stopRecording
    } = useAudioTranscription(appConfig.gatewayRealtime);
    const { init: initLLMReport, chatLLM, transcript: aiFullTranscript, isTranscribing } = useChatLLM();

    useEffect(() => {
        if (finalTranscript && finalTranscript.length > 0) {
            setMedicalReport({ ...medicalReport, [transcript_report_note]: finalTranscript });
        }
    }, [finalTranscript])

    const items = Object.entries(historyReports).map(([key, value]) => ({
        key: `clinical-note-${key}`,
        label: moment(value.timestamp).format('DD/MM/YYYY HH:mm'),
        children: <ReportEditor
            onReportDelete={async () => {
                try {
                    const transcriptNoteIds = lodash.get(value, transcript_report_note, []).map(note => note.id)
                    const aiSuggestionNoteIds = lodash.get(value, ai_suggestion_note, []).map(note => note.id)
                    await Promise.all(
                        [...transcriptNoteIds, ...aiSuggestionNoteIds].map(noteId => deleteClinicalReport(noteId))
                    );
                    message.success('Report deleted successfully');
                    if (onClose) onClose();
                } catch (e) {
                    console.error('Error deleting report:', e);
                }
            }}
            onMedicalReportChange={(inputKey, inputValue) => {
                setHistoryReports({
                    ...historyReports,
                    [key]: {
                        ...value,
                        [inputKey]: [
                            {
                                ...value[inputKey][0],
                                html: inputValue,
                            }
                        ],
                    }
                })
            }}
            medicalReport={{
                [transcript_report_note]: lodash.get(value, transcript_report_note, [])[0]?.html,
                [ai_suggestion_note]: lodash.get(value, ai_suggestion_note, [])[0]?.html,
            }} ></ReportEditor>
    }))

    const uploadProps = {
        beforeUpload: async (file) => {
            setFileList([...fileList, {
                uid: file.uid,
                name: file.name,
                status: 'done',
                url: URL.createObjectURL(file),
                file,
            }]);
            return false;
        },
        accept: '.pdf',
        fileList,
        maxCount: 1,
        multiple: false,
        showUploadList: false
    };

    const onRemove = (file) => {
        const filtredRemove = fileList.filter((item) => item.uid !== file.uid);
        const markRemove = fileList.filter((item) => item.uid === file.uid && item.status === uploadedStatus);
        setFileList(filtredRemove);
        setFileListMarkRemoveOnServer([...fileListMarkRemoveOnServer, ...markRemove]);
    }

    const [medicalReport, setMedicalReport] = useState({});
    const onMedicalReportChange = (key, value) => {
        setMedicalReport({ ...medicalReport, [key]: value });
    }
    const onCreateReport = () => setShowCreateReport(!showCreateReport)
    const onSubmitBt = async () => {
        try {
            const fileUploadPromises = fileList.filter((file) => file.file != null).map(file =>
                uploadReportAttachment(file.file, studyId)
            );

            if (Object.entries(medicalReport).length > 0) {
                let medicalReportNote = []
                if (medicalReport[study_report_note]) {
                    medicalReportNote = [
                        ...medicalReportNote,
                        {
                            note: medicalReport[study_report_note] || "",
                            type: study_report_note
                        }
                    ]
                }

                if (medicalReport[transcript_report_note] || medicalReport[ai_suggestion_note]) {
                    medicalReportNote = [
                        ...medicalReportNote,
                        {
                            note: `${medicalReport[transcript_report_note] || ""}<br />${medicalReport[ai_suggestion_note] || ""}`,
                            type: transcript_report_note
                        }
                    ]
                }

                if (medicalReportNote.length > 0) {
                    await submitClinicalReport(studyId, medicalReportNote);
                }
            }

            if (fileListMarkRemoveOnServer.length > 0) {
                await Promise.all(
                    fileListMarkRemoveOnServer.map(file =>
                        deleteFileClinicalReport(file.uid)
                    )
                );
            }

            // Execute all promises in parallel
            await Promise.all([
                ...fileUploadPromises,
            ]);

            if (onClose) onClose();
            message.success('Reports submitted successfully');
            setTimeout(() => {
                window.location.reload(true);
            }, 1000);
        } catch (error) {
            console.error('Error submitting reports:', error);
            message.error('Failed to submit reports');
        }
    };

    useEffect(() => {
        const fetchReport = async () => {
            try {
                const response = await getReportDetail(studyId);
                const noteGroup = groupNotesByDate(
                    response.data.transcriptReportNote,
                    response.data.aiReportNote
                );

                if (noteGroup && Object.keys(noteGroup).length > 0) {
                    setHistoryReports(noteGroup);
                }

                if (response.data.files) {
                    const mappedFile = await Promise.all(
                        response.data.files.map(async (file) => {
                            const reportContent = await downloadClinicalReportFile(file.id);
                            return {
                                uid: file.id,
                                name: file.file_name,
                                status: uploadedStatus,
                                url: URL.createObjectURL(reportContent),
                            }
                        })
                    );
                    setFileList(mappedFile);
                }

                if (response.data.studyReportNote) {
                    setMedicalReport({
                        [study_report_note]: response.data.studyReportNote || '',
                    });
                }
            } catch (error) {
                console.error('Error fetching report:', error);
            }
        };

        fetchReport();
    }, []);

    useEffect(() => {
        try {
            if (!getToken()) {
                message.error("AI service failed to established")
                return;
            }
            initLLMReport(appConfig.gatewayRealtime, getToken());
        } catch (e) {
            console.error('Error init to connect to llm', e);
        }
    }, []);

    useEffect(() => {
        if (aiFullTranscript && aiFullTranscript.length > 0) {
            setMedicalReport({ ...medicalReport, [ai_suggestion_note]: aiFullTranscript });
        }
    }, [aiFullTranscript])

    const shouldCreateButton = Object.entries(medicalReport).length > 0 || fileList.length > 0 || showCreateReport;

    useEffect(() => {
        return () => {
            stopRecording();
        };
    }, []);

    return (
        <Space direction="vertical" style={{ width: '100%' }} size="large">
            <div>
                <Title className='mt-0' level={3}>
                    Clinical Notes
                    {studyLink &&
                        <Button className='mt-1.5 ml-2.5 mb-2.5'
                            onClick={() => window.open(studyLink)}
                            icon={<EyeOutlined />}>View Study</Button>
                    }
                </Title>
                <Dragger {...uploadProps}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                        Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                        banned files.
                    </p>
                </Dragger>
                {fileList && fileList.length > 0 && <span>Files</span>}
                <Upload onRemove={onRemove} fileList={fileList} />
            </div>
            <div>
                <span>Notes</span>
                <TextArea value={medicalReport[study_report_note] || ""} onChange={(e) => {
                    onMedicalReportChange(study_report_note, e.target.value)
                }} rows={4} />
            </div>
            <div>
                <div className='flex justify-between items-center w-full'>
                    <Title level={3}>Report</Title>
                    <Button onClick={onCreateReport} icon={<PlusOutlined />}
                        className='bg-blue-500 hover:bg-blue-600 text-white rounded-md transition-colors duration-200'
                        type='primary'>Create Report</Button>

                </div>
                <div className='flex w-full mt-2.5'>
                    {items.length > 0 && <Collapse className='w-full' items={items} defaultActiveKey={['1']} />}
                </div>
                {showCreateReport &&
                    <ReportEditor
                        isCreating={true}
                        recordStatus={status}
                        medicalReport={medicalReport}
                        isTranscribing={isTranscribing}
                        onRequestTranscript={(type) => {
                            if (medicalReport[transcript_report_note] && medicalReport[transcript_report_note].length > 0) {
                                chatLLM(medicalReport[transcript_report_note], type);
                            }
                        }}
                        onRecordStart={() => {
                            startRecording(getToken());
                        }}
                        onRecordStop={() => {
                            stopRecording();
                        }}
                        onMedicalReportChange={(key, value) => {
                            if (key === transcript_report_note) {
                                // update back to finalTranscript transcribie
                                setFinalTranscript(value);
                            }
                            onMedicalReportChange(key, value)
                        }} />
                }

            </div>
            <div className='w-full flex gap-4 items-center'>
                <div className='w-full flex justify-between'>
                    {shouldCreateButton && closable && <Button onClick={onClose} className='w-1/2 mr-2.5' key="cancel-popup">Close</Button>}
                    {shouldCreateButton && <Button onClick={onSubmitBt} className={clsx('bg-blue-500 hover:bg-blue-600 text-white rounded-md transition-colors duration-200', { "w-1/2": closable, 'w-full': !closable })}
                        type='primary'
                        key="confirm-popup">
                        Save
                    </Button>}
                </div>

            </div>

        </Space>
    );
};

export default ClinicalReport;