const exportedConfig = {
  prod: {
    apiHost: "https://creni.org/api/v1/",
    uploaderApiHost: "https://creni.org/uploader",
    viewerHost: "https://creni.org/",
    moreLink: "https://creni.org/download-app/",
    gatewayRealtime: "https://realtime.creni.org",
    macOSLink: 'https://github.com/PotatoThanh/gateway/releases/latest/download/gateway-0.0.22-x64.dmg',
    windowLink: 'https://github.com/PotatoThanh/gateway/releases/latest/download/gateway-0.0.22.Setup.exe',
    sampleExcelLink: "https://github.com/PotatoThanh/MonLab-Cloud/raw/refs/heads/dev-new-ui/user-api/frontend/public/sample-file.xlsx",
    auth0: {
      domain: "monash-prod.au.auth0.com",
      clientId: "qiaGCyPxxmqHQ1rZeSAQ3HJKlO6wp7Nk",
      audience: "https://monash-prod.au.auth0.com/api/v2/"
    }
  },
  stg: {
    viewerHost: "https://staging.creni.org/",
    moreLink: "https://staging.creni.org/applications/",
    macOSLink: 'https://github.com/PotatoThanh/staging-gateway/releases/latest/download/gateway-staging-0.0.22-x64.dmg',
    windowLink: 'https://github.com/PotatoThanh/staging-gateway/releases/latest/download/gateway-staging-0.0.22.Setup.exe',
    uploaderApiHost: "https://staging.creni.org/uploader",
    gatewayRealtime: "https://staging-realtime.creni.org",
    apiHost: "https://staging.creni.org/api/v1/",
    sampleExcelLink: "https://github.com/PotatoThanh/MonLab-Cloud/raw/refs/heads/dev-new-ui/user-api/frontend/public/sample-file.xlsx",
    auth0: {
      domain: "dev-1qlarv4mylmgc4r7.au.auth0.com",
      clientId: "QfyAZLwJRjIV09UoclQJm8RsUhe6dl2d",
      audience: "https://dev-1qlarv4mylmgc4r7.au.auth0.com/api/v2/"
    }
  },
  dev: {
    uploaderApiHost: "http://localhost:7777",
    apiHost: "http://localhost:4500/v1/",
    viewerHost: "http://localhost:8080/",
    sampleExcelLink: "http://192.168.192.16:9999/applications/sample-file.xlsx",
    moreLink: "http://192.168.192.16:9999/applications/",
    macOSLink: 'http://192.168.192.16:9999/applications/gateway-1.0.0-x64.dmg',
    windowLink: 'https://creni.org/download-app/gateway-1.0.0-x64-win32.zip',
    auth0: {
      domain: "dev-1qlarv4mylmgc4r7.au.auth0.com",
      clientId: "QfyAZLwJRjIV09UoclQJm8RsUhe6dl2d",
      audience: "https://dev-1qlarv4mylmgc4r7.au.auth0.com/api/v2/"
    }
  },
};

export const appConfig = exportedConfig[process.env["BUILD_MODE"]];
