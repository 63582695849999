import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Spin, Modal, Form, Input, Button } from 'antd';
import { getLinkDetailByToken, registerAndAuthenticateUser, loginUser } from '../../apis/apis'
import { appConfig } from "../../config/config";
import { useAuth0 } from '@auth0/auth0-react';
import { message } from 'antd'
import Report from './Reportv2'

const PasswordFormModal = ({ handlePasswordSubmit }) => (
    <Modal
        title="Password Required"
        open={true}
        footer={null}
        closable={false}
        maskClosable={false}
    >
        <Form
            onFinish={handlePasswordSubmit}
            layout="vertical"
        >
            <Form.Item
                name="password"
                label="Password"
                rules={[{ required: true, message: 'Please input the password!' }]}
            >
                <Input.Password placeholder="Enter password" />
            </Form.Item>
            <Form.Item>
                <Button className='bg-blue-600 mt-2.5' type="primary" htmlType="submit" block>
                    Submit
                </Button>
            </Form.Item>
        </Form>
    </Modal>
);


export const ShareDetail = () => {
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [studyData, setStudyData] = useState(null);
    const [queryToken, setToken] = useState('');
    const [password, setUserPassword] = useState('');
    useEffect(() => {
        const token = searchParams.get('token');
        if (token) {
            setToken(token);
            if (password && password.length > 0) {
                fetchStudyDetails(token, password);
            }
        }
    }, [searchParams, password]);


    const { isAuthenticated, user } = useAuth0();
    const [isOwner, setIsOwner] = useState(false);

    const loginOrCreateUser = async (token) => {
        const password = `${token}1Asd!@#!aa221!@3`;
        const email = `${token}@creni.org`;
        try {
            const regsitrationResp = await registerAndAuthenticateUser({
                email,
                password
            });
            if (regsitrationResp) {
                const loginResp = await loginUser(email, password);
                return loginResp;
            }
        } catch (e) {
            if (e.response) {
                if (e.response.data.message.indexOf("User already exists") >= 0) {
                    const loginResp = await loginUser(email, password);
                    return loginResp;
                } else {
                    message.error("Something went wrong");
                    console.log(e);
                }
            }
        }
    }
    const fetchStudyDetails = async (token, password) => {
        try {
            setLoading(true);
            const resp = await getLinkDetailByToken(token, password);
            if (resp) {
                if (!isAuthenticated) {
                    const tokenResp = await loginOrCreateUser(token);
                    if (tokenResp) {
                        localStorage.setItem('token', tokenResp.access_token);
                    }
                }

                setStudyData(resp);
                if (isAuthenticated && user && user.sub === resp.user_id) {
                    setIsOwner(true);
                }
            }
        } catch (e) {
            setError(e);
            console.error(e);
        } finally {
            setLoading(false)
        }
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <Spin size="large" tip="Loading study details..." />
            </div>
        );
    }

    const handlePasswordSubmit = (password) => {
        setUserPassword(password.password);
    }

    if (!password || password.length === 0 || !studyData) {
        return <PasswordFormModal handlePasswordSubmit={handlePasswordSubmit} />
    }

    const studyID = studyData.study_id;
    return <Report
        isOwner={isOwner}
        studyLink={`${appConfig.viewerHost}ohif/viewer?StudyInstanceUIDs=${studyID}&token=${queryToken}`}
        closable={false}
        isOpen={studyID}
        studyId={studyID} />
};