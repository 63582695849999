import { useState } from "react";
import { Layout, Divider, Tabs, Modal, Button } from "antd";
import { Gateways } from './pages/Gateway'
import { Inbound } from './pages/Inbound'
import { Outbound } from './pages/Outbound'
import { GatewayWorkList } from './pages/GatewayWorkList'
import lodash from 'lodash'
export const AIPage = () => {
    const [currentLog, setCurrentLog] = useState('');
    const [logModalVisible, setLogModalVisible] = useState(false);
    const items = [
        {
            key: 'ai-gateways',
            label: 'AI Gateways',
            children: <Gateways />,
        },
        {
            key: 'inbound',
            label: 'Inbound',
            children: <Inbound onViewLog={(message) => {
                setLogModalVisible(true);
                setCurrentLog(message);
            }} />,
        },
        {
            key: 'outbound',
            label: 'Outbound',
            children: <Outbound onViewLog={(message) => {
                setLogModalVisible(true);
                setCurrentLog(message);
            }} />,
        },
        {
            key: 'workbook',
            label: 'Worklist',
            children: <GatewayWorkList onViewLog={(message) => {
                setLogModalVisible(true);
                setCurrentLog(message);
            }} onBreakclassClick={(record) => {
                setLogModalVisible(true);
                setCurrentLog({
                    // Patient fields:
                    PatientBirthDate: lodash.get(record, "patient.PatientBirthDate"),
                    PatientSex: lodash.get(record, "patient.PatientSex"),
                    PatientName: lodash.get(record, "patient.PatientName"),
                    PatientID: lodash.get(record, "patient.PatientID"),
                    PatientAge: lodash.get(record, "patient.PatientAge"),
                    PatientSize: lodash.get(record, "patient.PatientSize"),
                    PatientWeight: lodash.get(record, "patient.PatientWeight"),
                    // Study fields:
                    AccessionNumber: lodash.get(record, "study.AccessionNumber"),
                    ReferringPhysicianName: lodash.get(record, "study.ReferringPhysicianName"),
                    StudyDescription: lodash.get(record, "study.StudyDescription"),
                    StudyDate: lodash.get(record, "study.StudyDate"),
                    StudyTime: lodash.get(record, "study.StudyTim"),
                });
            }} />,
        },
    ];

    return (
        <Layout className="bg-white flex-row flex-col w-full">
            <div className="p-4 max-w-screen-2xl mx-0 center mx-auto">
                <h1 className="text-2xl font-bold text-center mb-4">AI</h1>
                <Divider />
                <Tabs defaultActiveKey="ai-gateways" items={items} />
            </div>
            {logModalVisible && <Modal
                title="Detail"
                visible={logModalVisible}
                onCancel={() => setLogModalVisible(false)}
                footer={[
                    <Button key="close" onClick={() => setLogModalVisible(false)}>
                        Close
                    </Button>,
                ]}
            >
                {typeof currentLog === 'string' ? (
                    <p>{currentLog}</p>
                ) : (
                    <div className="space-y-2">
                        {Object.entries(currentLog)
                            .map(([key, value]) => (
                                <div key={key} className="flex flex-col">
                                    <span className="font-semibold">{key}:</span>
                                    <span className="whitespace-pre-wrap">
                                        {lodash.isNil(value)
                                            ? "N/A"
                                            : lodash.isObject(value)
                                                ? JSON.stringify(value, null, 2)
                                                : value}
                                    </span>
                                </div>
                            ))}
                    </div>
                )}
            </Modal>}
        </Layout>
    );
};
