// useConversationSocket.js
import { useState, useRef, useEffect } from "react";
import { initSocket } from './socket';

export function useConversationSocket(socketUrl) {
  const [conversation, setConversation] = useState([]);

  // We'll keep track of the socket status
  // Possible values could be 'idle', 'connecting', 'connected', 'disconnected', 'error'
  const [status, setStatus] = useState("idle");

  // We'll store the socket reference here, so it persists across re-renders
  const socketRef = useRef(null);

  /**
   * Initialize (connect) the socket with the current token and conversationId.
   */
  const init = (conversationId, token) => {
    // If either token or conversationId is missing, we won't connect
    if (!token || !conversationId) {
      console.error("Missing token or conversationId, cannot init socket.");
      return;
    }

    // If there's an existing socket, disconnect it before creating a new one
    if (socketRef.current) {
      socketRef.current.disconnect();
    }

    // 1) Create a new socket instance
    setStatus("connecting");
    const socket = initSocket(socketUrl, token, conversationId);

    // 2) Connect the socket
    socket.connect();
    socketRef.current = socket;

    // 3) Listen for successful connection
    socket.on("connect", () => {
      console.log("Socket connected:", socket.id);
      setStatus("connected");

      // 4) Get all messages from the conversation once connected
      socket.timeout(5000).emit(
        "getConversation",
        { get: "Conversation" },
        (err, response) => {
          if (err) {
            console.error("getConversation error:", err);
            return;
          }
          console.log("Conversation:", response.data);
          setConversation(response.data);
        }
      );
    });

    // 5) If disconnected
    socket.on("disconnect", (reason) => {
      console.warn("Socket disconnected:", reason);
      setStatus("disconnected");
    });

    // 6) Subscribe to changes in the conversation
    socket.on("subscriberConversation", (response) => {
      console.log("New change:", response.type, response.data);
      switch (response.type) {
        case "post":
          setConversation((prev) => [...prev, response.data]);
          break;
        case "put":
          setConversation((prev) =>
            prev.map((msg) =>
              msg.id === response.data.id ? response.data : msg
            )
          );
          break;
        case "delete":
          setConversation((prev) =>
            prev.filter((msg) => msg.id !== response.data.id)
          );
          break;
        default:
          console.warn(
            "Unrecognized subscriberConversation response type:",
            response.type
          );
          break;
      }
    });

    // Optional: listen for error events
    socket.on("connect_error", (err) => {
      console.error("Connection error:", err);
      setStatus("error");
    });
  };

  /**
   * Cleanup when unmounting.
   */
  useEffect(() => {
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, []);

  // Helper methods to emit events
  const postConversation = (message) => {
    if (!socketRef.current) return;
    socketRef.current.timeout(5000).emit(
      "postConversation",
      { msg: message },
      (err, response) => {
        if (err) {
          console.error("postConversation error:", err);
        } else {
          console.log("New message created:", response);
        }
      }
    );
  };

  const putConversation = (id, message) => {
    if (!socketRef.current) return;
    socketRef.current.timeout(5000).emit(
      "putConversation",
      { id, msg: message },
      (err, response) => {
        if (err) {
          console.error("putConversation error:", err);
        } else {
          console.log("Message edited:", response);
        }
      }
    );
  };

  const deleteConversation = (id) => {
    if (!socketRef.current) return;
    socketRef.current.timeout(5000).emit(
      "deteleConversation",
      { id },
      (err, response) => {
        if (err) {
          console.error("deleteConversation error:", err);
        } else {
          console.log("Message deleted:", response);
        }
      }
    );
  };

  // Return everything the component needs to consume
  return {
    conversation,
    status,            // <-- The current socket connection status
    init,
    postConversation,
    putConversation,
    deleteConversation,
  };
}
