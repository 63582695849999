import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "code",
];

const TextEditor = ({ value, onChange, placeholder, showEditor }) => {
  const modules = showEditor
    ? {
      toolbar: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link", "code"],
        ["clean"],
      ],
    }
    : { toolbar: false }; // If showEditor is false, modules is set to false.

  const [editorHeight, setEditorHeight] = useState(130);
  useEffect(() => {
    if (value) {
      const textLength = value.replace(/<[^>]*>/g, '').length; // Remove HTML tags
      const minHeight = 130;
      const heightPerChar = 0.3; // Adjust this value to control expansion rate
      const calculatedHeight = Math.max(minHeight, textLength * heightPerChar);
      const maxHeight = 500; // Maximum height limit

      setEditorHeight(Math.min(calculatedHeight, maxHeight));
    } else {
      setEditorHeight(130); // Default height
    }
  }, [value]);

  return (
    <div style={{ height: `${editorHeight + 50}px` }}>
      <ReactQuill
        style={{ height: `${editorHeight}px` }}
        theme="snow"
        value={value || ""}
        modules={modules}
        readOnly={!showEditor}
        formats={formats}
        onChange={onChange}
        placeholder={placeholder}
      />
    </div>
  );
};

export default TextEditor;