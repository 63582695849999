import { serverAPIAxios, _generateAuthoriseHeader, _generateAuthoriseHeaderForm } from './init'


// User API calls
export const registerAndAuthenticateUser = async (data) => {
  try {
    const response = await serverAPIAxios.post("/users/", {
      ...data,
      email: data.email.toLowerCase(),
    });
    return response.data;
  } catch (error) {
    console.error("Error registerAndAuthenticateUser", error);
    throw error;
  }
};


export const updateUserPassword = async (password) => {
  try {
    const response = await serverAPIAxios.post("/users/update-password", {
      password,
    }, {
      headers: _generateAuthoriseHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("Error updateUserPassword", error);
    throw error;
  }
};

export const updateUserInformation = async (data) => {
  try {
    const response = await serverAPIAxios.post("/users/basic-information", {
      data,
    }, {
      headers: _generateAuthoriseHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("Error updateUserInformation", error);
    throw error;
  }
};

export const loginUser = async (email, password) => {
  try {
    const data = {
      email: email.toLowerCase(),
      password,
    };
    const response = await serverAPIAxios.post("/users/login", data);
    return response.data;
  } catch (error) {
    console.error("Error loginUser", error);
    throw error;
  }
};

// Gateway API calls
export const getCurrentGateways = async (company_id) => {
  try {
    const response = await serverAPIAxios.post(
      "/gateway",
      { company_id },
      {
        headers: _generateAuthoriseHeader(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error getCurrentGateways", error);
    throw error;
  }
};

export const getUserBasicInformation = async () => {
  try {
    const response = await serverAPIAxios.get(
      "/users/basic-information",
      {
        headers: _generateAuthoriseHeader(),
      }

    );
    return response.data;
  } catch (error) {
    console.error("Error getUserBasicInformation", error);
    throw error;
  }
};

export const sendFileToGateway = async (
  src_gateway_id,
  dest_gateway_id,
  studyInstanceID
) => {
  try {
    const response = await serverAPIAxios.post(
      "/gateway/send/file",
      {
        dest_gateway_id,
        src_gateway_id,
        studyInstanceID,
      },
      {
        headers: _generateAuthoriseHeader(),
        timeout: 50000000,
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error sending file to gateway:", error);
    throw error;
  }
};

export const listAllStudiesByUserID = async (page, pageSize, filterOptions) => {
  try {
    const response = await serverAPIAxios.post(
      `/studies/list?page=${page}&pageSize=${pageSize}`,
      {
        filter: filterOptions,
      },
      {
        headers: _generateAuthoriseHeader(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error sending file to gateway:", error);
    throw error;
  }
};

export const downloadStudy = async (study_id) => {
  try {
    const response = await serverAPIAxios.get(`/studies/download/${study_id}`, {
      headers: _generateAuthoriseHeaderForm(),
      responseType: "blob", // Important to handle binary data
    });
    return response.data;
  } catch (error) {
    console.error("Error upload file:", error);
    throw error;
  }
};

export const editStudy = async (id, dicom_data) => {
  try {
    const response = await serverAPIAxios.put(
      `/studies/${id}`,
      {
        dicom_data,
      },
      {
        headers: _generateAuthoriseHeader(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error editStudy:", error);
    throw error;
  }
};

export const deleteStudy = async (instance_id, study_id) => {
  try {
    const response = await serverAPIAxios.delete(`/studies`, {
      headers: _generateAuthoriseHeader(),
      data: { study_id, instance_id },
    });
    return response.data;
  } catch (error) {
    console.error("Error upload file:", error);
    throw error;
  }
};

export const editGateway = async (gateway_id, name) => {
  try {
    const response = await serverAPIAxios.put(
      `/gateway/`,
      {
        gateway_id,
        name,
      },
      {
        headers: _generateAuthoriseHeader(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error edit gateway:", error);
    throw error;
  }
};

export const getAllStudiesByGatewayID = async (gateway_id, name) => {
  try {
    const response = await serverAPIAxios.post(
      `/gateway/studies`,
      {
        gateway_id,
        name,
      },
      {
        headers: _generateAuthoriseHeader(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error edit gateway:", error);
    throw error;
  }
};

export const getGatewayStatus = async (gateway_id) => {
  try {
    const response = await serverAPIAxios.post(
      `/gateway/status`,
      {
        gateway_id,
      },
      {
        headers: _generateAuthoriseHeader(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error edit gateway:", error);
    throw error;
  }
};

export const getJobsStatus = async (
  gateway_id,
  studyInstanceID,
  destination
) => {
  try {
    const response = await serverAPIAxios.post(
      `/gateway/jobs/status`,
      {
        gateway_id,
        studyInstanceID,
        destination,
      },
      {
        headers: _generateAuthoriseHeader(),
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error edit gateway:", error);
    throw error;
  }
};

export const getTags = async (es_id) => {
  try {
    const response = await serverAPIAxios.post(
      `/studies/tags/${es_id}`,
      {},
      {
        headers: _generateAuthoriseHeader(),
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error get tags:", error);
    throw error;
  }
};

export const createForgotPassword = async (email) => {
  try {
    const response = await serverAPIAxios.post(
      `/users/forgot_request`,
      {
        email,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error get tags:", error);
    throw error;
  }
};

export const authorisedUser = async (requestData) => {
  try {
    const response = await serverAPIAxios.post(
      `/users/authorised`,
      requestData,
      {
        headers: {
          ..._generateAuthoriseHeader(),
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error authorisedUser", error);
    throw error;
  }
};


export const listAllSeriesByStudyID = async (studyID) => {
  try {
    const response = await serverAPIAxios.get(`/studies/detail/series/${studyID}`, {
      headers: _generateAuthoriseHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("Error listAllSeriesByStudyID", error);
    throw error;
  }
};

export const getPermissionByUsers = async () => {
  try {
    const response = await serverAPIAxios.get(`/permission/user`, {
      headers: _generateAuthoriseHeader(),
    });
    return response.data;
  } catch (error) {
    console.error("Error getPermissionByUsers", error);
    throw error;
  }
}

export const deleteSeriesByStudyId = async (series) => {
  try {
    const response = await serverAPIAxios.delete(`/studies/series/batch`, {
      headers: _generateAuthoriseHeader(),
      data: {
        series
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error getPermissionByUsers", error);
    throw error;
  }
}

export const fetchReports = async (studyId) => {
  try {
    const response = await serverAPIAxios.get(`/report/study/${studyId}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Failed to fetchReports');
    throw error;
  }
};

export const submitStudyReport = async (file, comments, author_name, report_file_id, study_id, user_id) => {
  try {
    const response = await serverAPIAxios.post(`/report/comments`, {
      study_id,
      comments,
      report_file_id,
      author_name,
      user_id
    });
    return response.data;
  } catch (error) {
    console.error('Failed to submitStudyReport');
    throw error;
  }
}

export const uploadReportAttachment = async (file, studyId) => {
  try {
    const formData = new FormData();
    formData.append('files', file);
    formData.append('study_id', studyId);
    formData.append('type', 'report_file');

    const response = await serverAPIAxios.post('/report/attachment', formData, {
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Failed to upload report attachment');
    throw error;
  }
};

export const getFileDownload = async (fileId) => {
  try {
    const response = await serverAPIAxios.get(`/report/attachments/${fileId}`, {
      responseType: "blob",
    });
    return response.data;
  } catch (error) {
    console.error('Failed to download file:', error);
    throw error;
  }
}

export const generateShareLink = async (study_id) => {
  if (!study_id) {
    throw new Error('Study ID is required');
  }

  try {
    const response = await serverAPIAxios.post(
      '/report/dicom/link',
      {
        study_id
      },
      {
        headers: {
          ..._generateAuthoriseHeader(),
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        }
      }
    );

    return response.data;
  } catch (error) {
    console.error('Failed to generate share link:', error);
    throw error;
  }
};

export const getLinkDetailByToken = async (token, password) => {
  try {
    const response = await serverAPIAxios.post(
      '/report/dicom/link/verify',
      {
        token,
        password
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    return response.data;
  } catch (error) {
    console.error('Failed to generate share link:', error);
    throw error;
  }
};

export const downloadReportFile = async (fileId) => {
  if (!fileId) {
    throw new Error('File ID is required');
  }

  try {
    const response = await serverAPIAxios.get(
      `/report/download`,
      {
        params: { file_id: fileId },
        headers: {
          'Accept': 'application/json, text/plain, */*'
        },
        responseType: 'blob' // Important for file downloads
      }
    );

    return response.data;
  } catch (error) {
    console.error('Failed to download file:', error);
    throw error;
  }
};

export const deleteComment = async (id) => {
  try {
    const response = await serverAPIAxios.delete(
      `/report/comments/${id}`,
    );

    return response.data;
  } catch (error) {
    console.error('Failed to deleteComment:', error);
    throw error;
  }
};

export const onSearcPeople = async (email) => {
  try {
    const response = await serverAPIAxios.get(
      `/users/query?email=${email}`,
      {
        headers: _generateAuthoriseHeader(),
      }
    );

    return response.data;
  } catch (error) {
    console.error('Failed to onSearcPeople:', error);
    throw error;
  }
}

export const shareDicomStudyAccessToUsers = async (study_id, token, email) => {
  try {
    const response = await serverAPIAxios.post("/report/dicom/link/user/access", {
      study_id,
      token,
      email
    }, {
      headers: _generateAuthoriseHeader(),
    });

    return response.data;
  } catch (error) {
    console.error("Error shareAccessToUsers", error);
    throw error;
  }
}

export const deleteUserFromShareDicom = async (study_id, token, email) => {
  try {
    const response = await serverAPIAxios.delete("/report/dicom/link/user/access", {
      headers: _generateAuthoriseHeader(),
      data: {
        study_id,
        token,
        email
      }
    });

    return response.data;
  } catch (error) {
    console.error("Error shareAccessToUsers", error);
    throw error;
  }
}

export const queryStudyShareDetail = async (study_id, token) => {
  try {
    const url = token
      ? `/report/dicom/link/user/access?study_id=${study_id}&token=${token}`
      : `/report/dicom/link/user/access?study_id=${study_id}`;

    const response = await serverAPIAxios.get(url);

    return response.data;
  } catch (error) {
    console.error("Error shareAccessToUsers", error);
    throw error;
  }
}

export const submitClinicalReport = async (study_id, report_notes) => {
  try {
    const response = await serverAPIAxios.post('/report/clinical', {
      study_id,
      report_notes
    });

    return response.data;
  } catch (error) {
    console.error("Error submitting clinical report:", error);
    throw error;
  }
};

export const updateClinicalReport = async (id, note) => {
  try {
    const response = await serverAPIAxios.put(`/report/clinical/${id}`, {
      note
    });

    return response.data;
  } catch (error) {
    console.error("Error updating clinical report:", error);
    throw error;
  }
}


export const getReportDetail = async (studyId) => {
  try {
    const response = await serverAPIAxios.get(`/report/detail/${studyId}`);

    return response.data;
  } catch (error) {
    console.error("Error getting report details:", error);
    throw error;
  }
};

export const deleteClinicalReport = async (id) => {
  try {
    const response = await serverAPIAxios.delete(`/report/clinical/${id}`);

    return response.data;
  } catch (error) {
    console.error("Error deleteClinicalReport:", error);
    throw error;
  }
};


export const deleteFileClinicalReport = async (id) => {
  try {
    const response = await serverAPIAxios.delete(`/report/attachment/${id}`);

    return response.data;
  } catch (error) {
    console.error("Error deleteFileClinicalReport:", error);
    throw error;
  }
};

export const downloadClinicalReportFile = async (fileId) => {
  try {
    const response = await serverAPIAxios.get(`/report/attachment/${fileId}`, {
      responseType: "blob"
    });

    return response.data;
  } catch (error) {
    console.error("Error downloadClinicalReportFile:", error);
    throw error;
  }
}