import React, { useState, useEffect, useMemo } from 'react';
import { Modal, Select, Input, Button, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import {
    generateShareLink,
    onSearcPeople,
    shareDicomStudyAccessToUsers,
    queryStudyShareDetail,
    deleteUserFromShareDicom,
} from "../../../apis/apis";
const { TextArea } = Input;

const buildLinkWithToken = (token) => {
    const currentDomain = window.location.origin; // Gets protocol + domain + port
    return `${currentDomain}/dashboard/detail?token=${token}`;
};

// Add this helper function
const truncateText = (text, startChars = 30, endChars = 30) => {
    if (text.length <= startChars + endChars) return text;
    return `${text.slice(0, startChars)}...${text.slice(-endChars)}`;
};

function ShareLinkv2({ isVisible, resourceId, onClose }) {
    const [userDropDownOptions, setUsersDropDownOptions] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [originalSelected, setOriginalSelectedEmail] = useState([]);
    const [shareLink, setShareLink] = useState('');
    const [token, shareToken] = useState('');
    const [password, setPassword] = useState('');
    const [invteMemssage, setInviteMessage] = useState('');

    useEffect(() => {
        if (isVisible) {
            generateLink();
        }
    }, [isVisible, resourceId]);

    const generateLink = async () => {
        try {
            const response = await generateShareLink(resourceId)
            if (response) {
                setShareLink(buildLinkWithToken(response.token));
                shareToken(response.token);
                setPassword(response.password);

                const studyShareDetail = await queryStudyShareDetail(resourceId, response.token);
                if (studyShareDetail.data && studyShareDetail.data.access.length > 0) {
                    const users = studyShareDetail.data.access.map(user => {
                        return {
                            email: user.email
                        }
                    });

                    const selectedUsers = users.map(user => user.email);
                    setSelectedUsers(selectedUsers);
                    setUsersDropDownOptions(users);
                    setOriginalSelectedEmail(selectedUsers);
                }
            }
        } catch (error) {
            console.log(error);
            message.error('Failed to generate share link');
        }
    };

    const copyToClipboard = async (input) => {
        try {
            await navigator.clipboard.writeText(input);
            message.success('Copied to clipboard!');
        } catch (error) {
            message.error('Failed to copy');
        }
    };

    const handleChange = (values) => {
        setSelectedUsers(values);
    };

    const onCopyLink = () => {
        copyToClipboard(shareLink);
    }

    const onCopyPasscode = () => {
        copyToClipboard(password);
    }

    const onAddUserAccess = async () => {
        if (selectedUsers.length === 0) {
            message.error('Please select users to add');
            return;
        }

        try {
            const deletedUsers = originalSelected.filter(email => !selectedUsers.includes(email));

            const addPromises = selectedUsers.map(email =>
                shareDicomStudyAccessToUsers(resourceId, token, email)
            );
            
            const deletePromises = deletedUsers.map(email =>
                deleteUserFromShareDicom(resourceId, token, email)
            );

            await Promise.all([...addPromises, ...deletePromises]);

            message.success('Users updated successfully');
            onClose();
        } catch (error) {
            console.error('Error updating users:', error);
            message.error('Failed to update users');
        }
    };

    const fetchPeople = async (value) => {
        const response = await onSearcPeople(value);
        return response
    }

    const onSearch = async (value) => {
        try {
            const response = await fetchPeople(value);
            if (response) {
                setUsersDropDownOptions(response.data);
            }
        } catch (e) {
            console.error(e);
        }
    }

    const usersOptions = useMemo(() => {
        return userDropDownOptions?.map(user => ({
            label: user.email,
            value: user.email
        })) || [];
    }, [userDropDownOptions]);

    const onMessageChange = (value) => {
        setInviteMessage(value);
    }

    return (
        <Modal width={500}
            visible={isVisible}
            closable={false}
            footer={null}
        >
            <p className="text-xl font-semibold">Add people to the chat</p>
            <span className="text-gray-500 text-sm font-light block mb-4">You can add people to the chat by email or using link and
                passcode</span>
            <p className="text-lg font-medium">Send invite through email</p>
            <span className="text-gray-500 text-sm font-light block mb-2">Email</span>
            <Select
                showSearch
                onSearch={onSearch}
                mode="multiple"
                value={selectedUsers}
                placeholder="Please select"
                onChange={handleChange}
                style={{ width: '100%' }}
                options={usersOptions}
            />
            <p className="text-lg font-medium mt-4 mb-2">Message</p>
            <TextArea onChange={onMessageChange} />
            <p className="text-lg font-medium mt-4">Send invite through Link and Passcode</p>
            <span className="text-gray-500 text-sm font-light block">Link</span>
            <Tag className='py-2 mt-2.5 flex justify-between items-center' color='blue'>
                <p className='text-wrap'>{truncateText(shareLink)}</p>
                <Button onClick={onCopyLink} className='border-none' icon={<CopyOutlined className="cursor-pointer" />}></Button>
            </Tag>

            <span className="text-gray-500 mt-2.5 text-sm font-light block">Passcode</span>
            <Tag className='w-full py-2 mt-2.5 flex justify-between items-center' color='blue'>
                <span>{password}</span>
                <Button onClick={onCopyPasscode} className='border-none' icon={<CopyOutlined className="cursor-pointer" />}></Button>
            </Tag>
            <div className='w-full flex gap-4 items-center mt-3.5'>
                <Button onClick={() => onClose()} className='w-1/2' key="cancel-popup">
                    Close
                </Button>
                <Button className='w-1/2 bg-blue-500 hover:bg-blue-600 text-white rounded-md transition-colors duration-200'
                    type='primary'
                    onClick={onAddUserAccess}
                    key="confirm-popup">
                    Confirm
                </Button>
            </div>
        </Modal>
    );
}

export default ShareLinkv2;