// useChatLLM.js
import { useState, useCallback, useRef } from 'react';
import { io } from 'socket.io-client';
import { debounce } from 'lodash';

export const PROMPT_SR_TYPE = 'PROMPT_SR';
export const PROMPT_LAY_LANG_TYPE = 'PROMPT_LAY_LANG';
export const PROMPT_CONCLUSION_TYPE = 'PROMPT_CONCLUSION'

export default function useChatLLM() {
  const [status, setStatus] = useState('idle'); // "idle" | "connecting" | "connected" | "disconnected" | "error"
  const [transcript, setTranscript] = useState('');
  const [isTranscribing, setIsTranscribing] = useState(false);
  const [error, setError] = useState(null);

  // Use a ref to store the socket so it persists without re-initializing on each render
  const socketRef = useRef(null);

  /**
   * init
   * Call this function to explicitly initialize the socket connection.
   *
   * @param {string} url  - Socket.io server URL
   * @param {string} token - Bearer token (e.g. "Bearer <JWT>")
   * @param {object} options - Additional socket.io-client options
   */
  const init = useCallback((url, token, options) => {
    // If the socket is already connected or connecting, avoid re-initializing
    if (socketRef.current?.connected || status === 'connecting') {
      console.warn('Socket is already connected or in the process of connecting.');
      return;
    }

    setStatus('connecting');

    const newSocket = io(url, {
      autoConnect: false,
      reconnection: true,
      auth: {
        jwt: token,
      },
      ...options,
    });

    // Connect
    newSocket.connect();

    // Listen for connect
    newSocket.on('connect', () => {
      console.log('Socket connected:', newSocket.id);
      setStatus('connected');
    });

    // Listen for disconnect
    newSocket.on('disconnect', (reason) => {
      console.log('Socket disconnected:', reason);
      setStatus('disconnected');
    });

    // Listen for error (optional, but helpful to track)
    newSocket.on('connect_error', (err) => {
      console.error('Socket connect_error:', err);
      setError(err);
      setStatus('error');
    });

    // Listen for your custom event
    newSocket.on('streamChatLLM', (data) => {
      console.log('Received data on streamChatLLM:', data.html);
      setTranscript(data.html || '');
      setIsTranscribing(true);
    });

    // Store socket instance in ref
    socketRef.current = newSocket;
  }, [status]);

  const debouncedEmit = useCallback(
    debounce((socket, prompt, promptType) => {
      socket.timeout(30000).emit(
        'chatLLM',
        { prompt: prompt.trim(), promptType },
        (err, response) => {
          if (err) {
            console.error('Timeout or error:', err);
            setError(err);
          } else {
            if (response.streamEnd === 'Stream finished.') {
              console.log('Stream finished. Response:', response);
              setIsTranscribing(false)
            }
          }
        }
      );
    }, 500), // 500ms delay
    []
  );

  /**
   * chatLLM
   * Send prompt data to the socket server.
   */
  const chatLLM = useCallback((prompt = '', promptType = '') => {
    const socket = socketRef.current;
    if (!socket || status !== 'connected') {
      console.warn('Socket is not connected yet. Current status:', status);
      return;
    }

    if (!prompt.trim()) {
      alert('Please enter a prompt.');
      return;
    }

    // Send the prompt with a 30s timeout
    debouncedEmit(socket, prompt, promptType);
  }, [status, debouncedEmit]);

  /**
   * disconnect
   * Explicitly disconnect the socket if needed.
   */
  const disconnect = useCallback(() => {
    const socket = socketRef.current;
    if (socket) {
      socket.disconnect();
      socketRef.current = null;
      setStatus('disconnected');
    }
  }, []);

  return {
    // Values and methods exposed by this hook
    status,        // "idle" | "connecting" | "connected" | "disconnected" | "error"
    transcript,    // The last transcript (HTML) received
    error,         // The last error encountered (if any)
    isTranscribing,

    init,          // Call to initialize the socket connection
    chatLLM,       // Call to send prompt data to the server
    disconnect,    // Call to disconnect the socket
  };
}
