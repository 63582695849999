import React from 'react';
import clsx from 'classnames'

function ChatMessage({ content, timestamp, isOwnMessage }) {
  const containerClass = isOwnMessage
    ? 'flex justify-end mb-4'
    : 'flex justify-start mb-4';

  return (
    <div className={containerClass}>
      <div className="flex flex-col">
        <div className={clsx({
          "bg-blue-400 text-grey px-4 py-2 rounded-lg max-w-xs break-words": isOwnMessage,
          'bg-gray-200 text-black px-4 py-2 rounded-lg max-w-xs break-words': !isOwnMessage
        })}>
          {content}<br />
          <span className="text-xs text-gray-600 mt-1 text-left">{timestamp}</span>
        </div>
      </div>
    </div>
  );
}

export default ChatMessage;
