import React, { useMemo } from 'react';
import { Button, Divider } from 'antd'
import JobTable from './Jobs'
import { useEffect } from "react";
import { buildQuery, buildQueryV2 } from "../../../apis/node";
import { ArrowRightOutlined } from '@ant-design/icons'
import {
    retrieveToServer,
    retrieveToGateway,
} from "../../../apis/job-status";
import { cancelRunningJobs, getJobsDetail } from '../../../apis/job-status';
import { hash } from '../../../libs/crypt'
import {
    CLOUD_PACS_MODE,
} from "../../constants/dicomNode";
import { message } from 'antd'
import { AppContext } from '../../../libs/context'
import { isJobStatusEligibleResend } from '../../../libs/status'
import { useContext } from 'react'
import { MODAL_MANAGE_JOBS_MODAL } from '../../../../src/pages/constants/modals';
import moment from "moment";
import lodash from 'lodash';
import { SendOutlined, RedoOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import { TransferConfirmation } from '../../Dashboard/TransferConfirm'

const JobWrapper = () => {
    const { store, setCurrentModal } = useContext(AppContext)
    const {
        currentSelectedSeries,
        gatewayData,
        selectedSourceGatewayId,
        selectedDestinationGatewayId,
        selectedSourceDicomNodeId,
        selectedDestinationDicomId,
        addJob,
        updateRunningJobs,
        removeJob,
        runningJobs,
        jobStatusMap,
    } = store;
    const { selectedJobs, clearAllSelectedJobs, addJobToCache, cacheAllJobs } = store;
    const { queueJobs, setJobs } = store;
    const { cancelLocalJobs } = store;
    const { pendingQueueJobs, setPendingJobs, getLatestPendingJob } = store;
    const [isOpenTransferConfirmation, setIsOpenTransferConfirmation] = React.useState(false);

    const groupJobsByStudyId = (inputData) => {
        const groupedData = {};

        inputData.forEach(item => {
            const { studyId, patientName, patientID, studyDescription, id, name, status, createdAt, updatedAt } = item;

            if (!groupedData[studyId]) {
                groupedData[studyId] = {
                    id: studyId,
                    patientName,
                    patientID,
                    studyDescription,
                    jobs: []
                };
            }

            // Push the job information into the jobs array
            groupedData[studyId].jobs.push({
                id,
                name,
                status,
                createdAt,
                updatedAt
            });
        });

        // Convert the grouped object into an array format
        return Object.values(groupedData);
    }

    const filterRunningJob = (jobs) => {
        return jobs.filter(
            (job) =>
                job.status !== "job-success" &&
                job.status !== "job-failure" &&
                job.status !== "job_canceled" &&
                job.status !== "deleting" &&
                job.status !== null
        );
    }

    const filterDoneJob = (jobs) => {
        return jobs.filter(
            (job) =>
                job.status === "job-success"
        );
    }

    const addOrUpdateJob = async ({
        patientName,
        patientID,
        studyDescription,
        studyId,
        sendWithAnonymous,
        ...rest
    }, response) => {
        if (response && response.data && response.data.id) {
            const jobResponse = await getJobsDetail(response.data.id);
            if (jobResponse.data) {
                const job = jobResponse.data[0];
                addJobToCache({
                    ...rest,
                    ...job,
                    selectedSourceGatewayId,
                    selectedSourceDicomNodeId,
                    selectedDestinationDicomId,
                    selectedDestinationGatewayId,
                    sendWithAnonymous,
                    studyDescription,
                    patientID,
                });
                addJob([{
                    ...job,
                    patientName,
                    patientID,
                    studyDescription,
                    studyId
                }]);

                updateRunningJobs(jobResponse.data);

                if (job.status === 'job-failure' && jobIsRunning.length === 0) {
                    pullingTopJobToSend();
                }
            }
        }
    };

    // ALERT if job is running
    const beforeunloadWarning = (e) => {
        e.preventDefault();
        e.returnValue = ''; // Standard way to trigger a warning message in modern browsers
    };

    useEffect(() => {
        // Function to add the beforeunload event listener
        const addAlertRunningJob = () => {
            window.addEventListener('beforeunload', beforeunloadWarning);
        };

        // Function to remove the beforeunload event listener
        const removeAlertRunningJob = () => {
            window.removeEventListener('beforeunload', beforeunloadWarning);
        };

        const jobs = filterRunningJob(runningJobs);

        if (pendingQueueJobs.length > 0 || jobs.length > 0) {
            addAlertRunningJob();
        } else {
            removeAlertRunningJob();
        }

        // Clean up the event listener on component unmount
        return () => {
            removeAlertRunningJob();
        };
    }, [runningJobs, pendingQueueJobs]);

    const [messageApi, contextHolder] = message.useMessage();
    useEffect(() => {
        const jobs = filterRunningJob(runningJobs);

        const updateJobStatus = async () => {
            const updates = [];
            messageApi.open({
                key: "studies-trasnfering",
                type: 'loading',
                content: 'Sending...',
                duration: 0,
            });

            for (let job of jobs) {
                try {
                    const jobResponse = await getJobsDetail(job.id);
                    const jobStatus = jobResponse.data?.[0]?.status;
                    const name = jobResponse.data?.[0]?.name;

                    // Collect updates
                    if (job.status !== jobStatus) {
                        // Only collect updates if there's a change
                        updates.push({ id: job.id, status: jobStatus, name });
                    }

                    if (jobStatus === "job-success") {
                        if (selectedDestinationGatewayId !== CLOUD_PACS_MODE) {
                            messageApi.open({
                                key: "studies-trasnfering",
                                type: 'success',
                                content: 'Images all uploaded to destination pacs system"',
                                duration: 1,
                            });
                        }
                    }
                } catch (error) {
                    console.error("Error updating job status:", error);
                }
            }

            // Batch update runningJobs if there are updates
            if (updates.length > 0) {
                updateRunningJobs(updates);
            }
        };

        let intervalId = null;

        if (jobs.length > 0) {
            updateJobStatus();
            intervalId = setInterval(updateJobStatus, 5000);
        }

        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, [runningJobs]);

    // this is the hook update toast message when job done
    const jobIsRunning = useMemo(() =>
        filterRunningJob(runningJobs),
        [runningJobs]
    );
    useEffect(() => {
        const updateJobListSend = () => {
            if (runningJobs.length === 0) {
                return;
            }

            if (jobIsRunning.length === 0) {
                messageApi.open({
                    key: 'studies-trasnfering',
                    type: 'info',
                    content: 'All jobs have completed',
                    duration: 3,
                });

                pullingTopJobToSend();
            }
        }

        updateJobListSend();
    }, [jobIsRunning.length])

    const generateHashFromStudyId = (studyUID, anonymousName) => {
        if (anonymousName) {
            return anonymousName;
        }

        const currentGateway = selectedSourceGatewayId && gatewayData.find((gateway) => gateway.id === selectedSourceGatewayId);
        return `GATEWAY:${currentGateway.name}:${hash(studyUID)}`
    }

    const { getAnonymousNameMap } = store
    const getAnonymousName = (patientID, accessionNumber) => {
        if (!patientID && !accessionNumber) {
            return null;
        }

        return getAnonymousNameMap(patientID || accessionNumber);
    }

    // if study id not inside map then send without PHI will use this instead
    // only applicaabale for batch transfering
    const generateHash = (studyUID, patientID, accessionNumber) => {
        const anonymousName = getAnonymousName(patientID, accessionNumber);
        if (anonymousName && anonymousName.length > 0) {
            return anonymousName;
        }

        // if study id not inside map then send without PHI will use this instead
        // only applicaabale for batch transfering
        return generateHashFromStudyId(studyUID);
    }

    const sendSeries = async (selectedSourceGatewayId, selectedSourceDicomNodeId, selectedDestinationDicomId, selectedSeries, selectedDestinationGatewayId, sendWithAnonymous) => {
        try {
            if (selectedDestinationGatewayId === CLOUD_PACS_MODE) {
                for (const item of selectedSeries) {
                    if (!item.seriesUID || !item.studyUID) {
                        continue;
                    }
                    const response = await retrieveToServer({
                        gateway_id: selectedSourceGatewayId,
                        node_id: selectedSourceDicomNodeId,
                        modify_tags: item.modifyTags,
                        anonymous: sendWithAnonymous.toString(),
                        priority: 1,
                        tags: buildQuery({
                            seriesUID: item.seriesUID,
                            studyInstanceUID: item.studyUID,
                            acquisitionTime: item.acquisitionTime,
                            acquisitionNumber: item.acquisitionNumber,
                            acquisitionDate: item.acquisitionDate,
                            accessionNumber: item.accessionNumber,
                            modality: item.modality,
                            seriesDescription: item.description,
                            birthday: item.birthDate,
                            retrieveAE: item.retrieveAE,
                            gender: item.gender,
                            studyDate: item.date,
                            referringPhysicianName: item.institution,
                            patientID: item.patientID,
                            numOfSeriesRelated: item.numOfSeriesRelated,
                            patientName: item.patientName,
                            studyDescription: item.studyDescription,
                            type: "SERIES",
                        }),
                    });
                    addOrUpdateJob({
                        ...item,
                        patientName: item.patientName,
                        patientID: item.patientID,
                        studyId: item.studyUID,
                        studyDescription: item.studyDescription,
                        sendWithAnonymous,
                    }, response);
                }

            } else {
                for (const item of selectedSeries) {
                    if (!item.seriesUID || !item.studyUID) {
                        continue;
                    }
                    const response = await retrieveToGateway({
                        source_gateway_id: selectedSourceGatewayId,
                        source_node_id: selectedSourceDicomNodeId,
                        target_gateway_id: selectedDestinationGatewayId,
                        target_node_id: selectedDestinationDicomId,
                        modify_tags: item.modifyTags,
                        anonymous: sendWithAnonymous.toString(),
                        priority: 1,
                        tags: buildQuery({
                            patientName: item.patientName,
                            birthday: item.birthDate,
                            studyDate: item.date,
                            gender: item.gender,
                            seriesDescription: item.description,
                            retrieveAE: item.retrieveAE,
                            referringPhysicianName: item.institution,
                            seriesUID: item.seriesUID,
                            studyInstanceUID: item.studyUID,
                            acquisitionTime: item.acquisitionTime,
                            acquisitionNumber: item.acquisitionNumber,
                            acquisitionDate: item.acquisitionDate,
                            accessionNumber: item.accessionNumber,
                            numOfSeriesRelated: item.numOfSeriesRelated,
                            modality: item.modality,
                            patientID: item.patientID,
                            studyDescription: item.studyDescription,
                            type: "SERIES",
                        }),
                    });
                    addOrUpdateJob({
                        ...item,
                        patientName: item.patientName,
                        patientID: item.patientID,
                        studyId: item.studyUID,
                        studyDescription: item.studyDescription,
                        sendWithAnonymous,
                    }, response);
                }
            }
        } catch (e) {
            const errMessage = lodash.get(e, "response.data.message", null);
            if (errMessage) {
                message.error(errMessage);
            }
            console.error(e);
            pullingTopJobToSend();
        }

    }

    const removeOnTopJobQueue = () => {
        if (queueJobs.length > 0) {
            queueJobs.pop();
            setJobs([...queueJobs]);
        }
    }

    const pullingTopJobToSend = () => {
        removeOnTopJobQueue();
        pullingTask();
    }

    const [transferConfirmationData, setTransferConfirmationData] = React.useState({});
    const onSend = async (sendWithAnonymous) => {
        try {
            if (!currentSelectedSeries || currentSelectedSeries.length === 0) {
                message.error("Please select at least one series to send");
                return;
            }
            
            setTransferConfirmationData(buildTransferConfirmationData(sendWithAnonymous));
            setIsOpenTransferConfirmation(true);
        } catch (e) {
            message.error(e.response.data.message);
            console.error(e);
        }
    };


    useEffect(() => {
        const removeLastJob = async () => {
            if (queueJobs.length > 0 && filterRunningJob(runningJobs).length === 0) {
                const { selectedSourceGatewayId, selectedSourceDicomNodeId, selectedDestinationDicomId, selectedDestinationGatewayId } = queueJobs[0];
                await sendSeries(selectedSourceGatewayId, selectedSourceDicomNodeId, selectedDestinationDicomId, queueJobs, selectedDestinationGatewayId, queueJobs[0].sendWithAnonymous);
            }
        };

        removeLastJob();
    }, [queueJobs]);


    const pullingTask = () => {
        const latestPendingJobs = getLatestPendingJob();
        if (queueJobs.length === 0 && latestPendingJobs.length !== 0) {
            let newQueuePendingJob = [...latestPendingJobs];
            const newJob = newQueuePendingJob.pop();
            setPendingJobs([...newQueuePendingJob]);
            setJobs([newJob]);
        }
    }

    const handleCancelLocalJob = async (ids) => {
        // Filter out the jobs with the specified ids
        cancelLocalJobs(ids);
        return true;
    }

    const onCancelJobs = async () => {
        if (!selectedSourceGatewayId) {
            return;
        }

        if (!selectedJobs || selectedJobs.length === 0) return;

        const selectedJobsToArray = Array.from(selectedJobs);
        try {
            messageApi.open({
                content: 'Cancelling jobs...',
                duration: 0,
                type: 'loading',
                key: 'canceling-jobs'
            })

            const filteredLocalJobs = selectedJobsToArray.filter((jobId) => {
                return typeof jobId === 'string' && jobId.indexOf("local") >= 0
            });
            if (filteredLocalJobs.length > 0) {
                handleCancelLocalJob(filteredLocalJobs);
            }

            const filterServerJobs = selectedJobsToArray.filter((jobId) => {
                return typeof jobId !== 'string';
            });
            const cancelPromises = filterServerJobs.map(jobId => {
                return cancelRunningJobs(selectedSourceGatewayId, jobId);
            });

            await Promise.all(cancelPromises);

            clearAllSelectedJobs();
            removeOnTopJobQueue();

            messageApi.open({
                content: 'Jobs cancelled',
                duration: 2,
                type: 'success',
                key: 'canceling-jobs'
            })
        } catch (error) {
            console.error(error);
            messageApi.open({
                content: 'Failed to cancel jobs',
                duration: 2,
                type: 'error',
                key: 'canceling-jobs'
            })
        }
    }

    const onResendJob = () => {
        const jobsToIds = Array.from(selectedJobs);

        const mapIdsToJobs = jobsToIds.map((id) => {
            return cacheAllJobs[id] || null;
        })

        messageApi.open({
            content: 'Resending jobs',
            duration: 2,
            type: 'info',
            key: 'resending-job'
        })

        let newQueueJob = [...getLatestPendingJob()];
        let hasJobStatusNeedWarning = false;
        let deleteJobs = [];
        // because status not only "job-success", "job-failure", "job_canceled", mix some other status so need warning
        mapIdsToJobs.forEach((job) => {
            if (job) {
                if (jobStatusMap[job.id] && isJobStatusEligibleResend(jobStatusMap[job.id])) {
                    newQueueJob = [
                        ...newQueueJob,
                        {
                            ...job,
                            studyId: job.studyUID,
                            patientName: job.patientName,
                            patientID: job.patientID,
                            name: `Pending ${job.description}`,
                            status: 'pending',
                            id: `local-${uuidv4()}`,
                        }
                    ]

                    deleteJobs = [...deleteJobs, { id: job.id, status: 'deleting', name: job.name }];
                } else {
                    hasJobStatusNeedWarning = true;
                }
            }
        })

        if (newQueueJob && newQueueJob.length > 0) {
            setPendingJobs(newQueueJob);
        }

        if (hasJobStatusNeedWarning) {
            message.warning(
                "Warning: Only jobs with the status 'Success,' 'Failure,' or 'Canceled' can be resent. Jobs with other statuses will be skipped."
            );
        }

        if (jobIsRunning.length === 0) {
            pullingTopJobToSend();
        } else {
            pullingTask();
        }

        if (deleteJobs.length > 0) {
            deleteJobs.forEach((job) => {
                removeJob(job.id);
            });
        }
    }

    const onShowHistory = () => {
        setCurrentModal({
            modal: MODAL_MANAGE_JOBS_MODAL,
            data: null,
        });
    }

    const sortJobLatest = (jobs) => {
        return jobs
            .map(item => ({
                ...item,
                createdAt: item.createdAt ? moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss") : "N/A",
            }))
            .sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)));

    }

    const groupRunningJobs = groupJobsByStudyId([...getLatestPendingJob(), ...sortJobLatest(runningJobs.filter((job) => job.status !== "job-success" && job.status != "deleting"))])
    const groupDoneJobs = groupJobsByStudyId(filterDoneJob(sortJobLatest(runningJobs)))

    const onCloseTransferConfirmation = () => {
        setIsOpenTransferConfirmation(false);
    }

    const buildTransferConfirmationData = (sendWithAnonymous) => {
        const { sourceDicomNodes, destinationDicomNodes } = store;
        const currentSourceGateway = selectedSourceGatewayId && gatewayData.find((gateway) => gateway.id === selectedSourceGatewayId);
        const currentDestinationGateway = selectedDestinationGatewayId && gatewayData.find((gateway) => gateway.id === selectedDestinationGatewayId);
        const sourceGatewayName = currentSourceGateway && currentSourceGateway.name;
        const selectedSourceDicomNode = sourceDicomNodes && sourceDicomNodes.find((dicomNode) => dicomNode.id === selectedSourceDicomNodeId);
        const selectedDestinationDicomNode = destinationDicomNodes && destinationDicomNodes.find((dicomNode) => dicomNode.id === selectedDestinationDicomId);
        let destinationGatewayName = currentDestinationGateway && currentDestinationGateway.name;
        let destinationDicomNodeName = selectedDestinationDicomNode && selectedDestinationDicomNode.name;

        if (selectedDestinationGatewayId === 'cloud-pacs' && !destinationGatewayName) {
            destinationGatewayName = 'Cloud PACS';
            destinationDicomNodeName = "N/A";
        }

        // data prepare to send
        let sourceStudyData = [];
        if (currentSelectedSeries && currentSelectedSeries.length > 0) {
            // Group series by studyUID
            const studyMap = currentSelectedSeries.reduce((acc, series) => {
                // Skip if no studyUID
                if (series.children) {
                    acc[series.studyInstanceUID] = {
                        patientName: series.patientName,
                    }
                    return acc;
                }

                const {
                    studyUID,
                    date,
                    modality,
                    seriesUID,
                    physicianName,
                } = series;

                if (!acc[studyUID] || !acc[studyUID].children) {
                    // Create study entry if it doesn't exist
                    acc[studyUID] = {
                        ...acc[studyUID],
                        ...series,
                        studyDate: date,
                        modality: modality || 'N/A',
                        sendWithAnonymous,
                        children: []
                    };

                    if (physicianName && !acc[studyUID].patientName) {
                        acc[studyUID].patientName = physicianName;
                    }
                }

                // Only add series if it has a valid seriesUID
                if (seriesUID) {
                    acc[studyUID].children.push({
                        ...series,
                        sendWithAnonymous,
                        modality: modality || 'N/A'
                    });
                }

                return acc;
            }, {});

            // Convert map to array and filter out any invalid entries
            sourceStudyData = Object.values(studyMap).filter(study =>
                study.studyUID &&
                study.children &&
                study.children.length > 0
            );
        }

        return {
            sourceGateway: {
                id: selectedSourceGatewayId,
                name: sourceGatewayName,
                dicomNode: {
                    id: selectedSourceDicomNodeId,
                    name: selectedSourceDicomNode.name
                }
            },
            destinationGateway: {
                id: selectedDestinationGatewayId,
                name: destinationGatewayName,
                dicomNode: {
                    id: selectedDestinationDicomId,
                    name: destinationDicomNodeName
                }
            },
            sourceStudyData,
            sendWithAnonymous
        }
    }


    const hasDataChange = (currentSelectedSourceData, selectedStudy) => {
        // Define fields to compare
        const fieldsToCompare = [
            'patientName',
            'patientID',
            'birthDate',
            'gender',
            'accessionNumber'
        ];

        // Check each non-empty field
        return fieldsToCompare.some(field => {
            const selectedValue = lodash.get(selectedStudy, field);
            const sourceValue = lodash.get(currentSelectedSourceData, field);

            // Only compare if selectedValue exists and is not empty
            if (lodash.isEmpty(selectedValue)) {
                return false;
            }

            // Case-insensitive string comparison
            return !lodash.isEqual(
                lodash.toLower(selectedValue),
                lodash.toLower(sourceValue)
            );
        });
    };

    const onStudyConfirm = (currentSelectedSourceData, selectedStudy) => {
        const hasChanged = hasDataChange(currentSelectedSourceData, selectedStudy);
        const { sendWithAnonymous } = currentSelectedSourceData;
        let modifyTags = [];
        if (hasChanged || sendWithAnonymous) {
            modifyTags = buildQueryV2({
                patientName: selectedStudy.patientName,
                patientID: selectedStudy.patientID,
                birthday: selectedStudy.birthDate,
                gender: selectedStudy.gender,
                accessionNumber: selectedStudy.accessionNumber,
            });
        }

        let newQueueJob = [...pendingQueueJobs];
        currentSelectedSourceData.children.forEach((currentSelectedSeries) => {
            const job = {
                ...currentSelectedSeries,
                modifyTags,
                sendWithAnonymous,
                name: `Pending ${currentSelectedSeries.description}`,
                acquisitionNumber: currentSelectedSeries.acquisitionNumber,
                status: 'pending',
                id: `local-${uuidv4()}`,
                patientName: currentSelectedSourceData.patientName,
                patientID: currentSelectedSourceData.patientID,
                studyUID: currentSelectedSourceData.studyUID,
                studyId: currentSelectedSourceData.studyUID,
                studyDescription: currentSelectedSourceData.studyDescription,
                selectedSourceGatewayId,
                selectedSourceDicomNodeId,
                selectedDestinationDicomId,
                selectedDestinationGatewayId,
            }

            addJobToCache({
                ...job,
                sendWithAnonymous,
                studyDescription: currentSelectedSeries.studyDescription,
            });

            newQueueJob = [...newQueueJob, job]
        });

        setPendingJobs(newQueueJob);
        pullingTask();
    }

    return (
        <div className='flex flex-col w-full'>
            <div className='flex flex-row w-full'>
                {contextHolder}
                <div className="w-1/2 mr-2.5">
                    <div className="w-full">
                        <div className="flex-row w-full items-center justify-center mt-4 mb-5">
                            <div className="flex flex-col">
                                <div className='flex flex-row'>
                                    <h2 className="mr-4 text-xl font-bold mb-3">Processing:</h2>
                                    <Button icon={<SendOutlined />} onClick={() => onSend(false)} className="mx-2 bg-blue-500 hover:bg-blue-600 text-white">Send</Button>
                                    <Button icon={<SendOutlined />} onClick={() => onSend(true)} className="mx-2 bg-blue-500 hover:bg-blue-600 text-white">Send without PHI</Button>
                                    <Button icon={<RedoOutlined />} disabled={selectedJobs.size === 0} onClick={onResendJob} className="mx-2 bg-blue-500 hover:bg-blue-600 text-white">Resend</Button>
                                </div>
                                <p class="text-sm text-gray-500">Note: Please click Send button to transfer selected images to destination.</p>
                            </div>
                        </div>
                    </div>
                    <JobTable jobs={groupRunningJobs} showRowSelection={true} />
                </div>
                <div className="mr-2.5 items-center justify-center mb-10">
                    <Divider className='ml-5 h-1/2 border-gray-400' type='vertical'></Divider>
                    <div className='w-full mt-2.5 mb-2.5'>
                        <ArrowRightOutlined className="text-2xl ml-2.5" />
                    </div>
                    <Divider className='ml-5 h-1/2 border-gray-400' type='vertical'></Divider>
                </div>
                <div className="w-1/2">
                    <div className="w-full">
                        <div className="flex-row w-full items-center justify-center mt-4 mb-5">
                            <div className="flex items-center">
                                <h2 className="mr-4 text-xl font-bold mb-3">Arrived:</h2>
                                <div className="w-full flex justify-end">
                                    <Button onClick={onShowHistory} className="mx-2 bg-blue-500 hover:bg-blue-600 text-white">Jobs History</Button>
                                    <Button disabled={selectedJobs.size === 0}
                                        onClick={onCancelJobs}
                                        className="mx-2 bg-red-500 hover:bg-red-600 text-white">Cancel Selected Jobs</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <JobTable onResendJob={onResendJob} jobs={groupDoneJobs} showRowSelection={false} />
                </div>
            </div>
            {isOpenTransferConfirmation && transferConfirmationData &&
                <TransferConfirmation
                    onClose={onCloseTransferConfirmation}
                    onStudyConfirm={onStudyConfirm}
                    {...transferConfirmationData}
                />}
        </div>

    );
};

export default JobWrapper;
