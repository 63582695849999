import React, { useState, useEffect } from 'react';
import { Modal, Table, Button, Spin } from 'antd';
import { SearchGatewayFilter } from "../../pages/components/Filters/Gateway";
import { listAllPublicGatewayByEmail } from "../../apis/gateway";
import { isOfflineStatus, isListenerOfflineStatus } from '../../libs/status'

const SelectGatewayAndDicomModal = ({
    isLoadingGateway,
    isLoadingDicom,
    onCancel, gatewayData, dicomNodes,
    selectedGatewayId, selectedDicomNodeId,
    onGatewaySelect,
    onDicomSelect,
    onSearchGatewayResult,
    onSubmit }) => {
    const gatewayColumns = [
        { title: 'id', dataIndex: 'id', key: 'id', hidden: true },
        { title: 'Gateway Name', dataIndex: 'name', key: 'name', render:(_, record) => {
            return <p className="break-words whitespace-normal w-[100px]">{record.name}</p>;
        } },
        {
            title: 'Gateway Status',
            dataIndex: 'status',
            key: 'status', render: (_, record) => {
                if (isOfflineStatus(record)) {
                    return <p className='text-red-500'>{record.status}</p>
                }

                return <p>{record.status}</p>
            }
        },
        {
            title: 'Listener IP', dataIndex: 'listener_ip', key: 'listener_ip', render: (_, record) => {
                return <p className="break-words whitespace-normal w-[100px]">{record.listener_ip}</p>;
            }
        },
        {
            title: 'Listener Status', dataIndex: 'listener_status', key: 'listener_status', render: (_, record) => {
                if (isListenerOfflineStatus(record)) {
                    return <p className='text-red-500'>{record.listener_status}</p>
                }

                return <p>{record.listener_status}</p>
            }
        },
        { title: 'Type', dataIndex: 'type', key: 'type' },
    ];

    const dicomNodeColumns = [
        {
            title: "Name",
            dataIndex: "name",
            className: "text-xs",
            render: (_, record) => <p>{record.name}</p>,
        },
        {
            title: "AET",
            dataIndex: "aet",
            className: "text-xs",
        },
        {
            title: "Address",
            dataIndex: "ip",
            className: "text-xs",
            render: (_, record) => (
                <>
                    {record.ip || ""}
                    <br></br>
                    {record.port || ""}
                </>
            ),
        },
        {
            title: 'Status', dataIndex: 'status', key: 'status', render: (_, record) => {
                if (isOfflineStatus(record)) {
                    return <p className='text-red-500'>{record.status}</p>
                }

                return <p>{record.status}</p>
            }
        },
    ];

    const [gateway, setGateway] = useState(selectedGatewayId || null);
    const [dicomNode, setDicomNodeId] = useState(selectedDicomNodeId || null);

    useEffect(() => {
        if (!gateway && selectedGatewayId) {
            setGateway({
                id: selectedGatewayId,
            });
        }

        if (!dicomNode && selectedDicomNodeId) {
            setDicomNodeId(selectedDicomNodeId);
        }
    }, [selectedGatewayId, selectedDicomNodeId]);

    useEffect(() => {
        if (selectedGatewayId && gatewayData.length > 0) {
            const selectedGateway = gatewayData.find(gateway => gateway.id === selectedGatewayId);
            if (selectedGateway) {
                setGateway(selectedGateway);
            }
        }

        if (selectedDicomNodeId && dicomNodes.length > 0) {
            const selectedDicom = dicomNodes.find(dicomNode => dicomNode.id === selectedDicomNodeId);
            if (selectedDicom) {
                setDicomNodeId(selectedDicom.id);
            }
        }
    }, []);


    const handleGatewaySelect = (selectedRowKeys, selectedRows) => {
        setGateway(selectedRows[0]);
        onGatewaySelect(selectedRows[0]);
    };

    const handleDicomSelect = (dicoms) => {
        setDicomNodeId(dicoms[0]);
        onDicomSelect(dicoms[0]);
    };

    const onSearchGateway = async (value) => {
        if (!value || value.trim().length === 0) {
            onSearchGatewayResult([]);
            return;
        }

        if (value && value.trim().length > 0) {
            try {
                const response = await listAllPublicGatewayByEmail(value);
                if (response.data) {
                    onSearchGatewayResult(response.data);
                }
            } catch (e) {
                console.error(e);
                onSearchGatewayResult([]);
            }
        }
    };

    const handleOk = () => {
        if (onSubmit) {
            onSubmit({
                gatewayId: gateway.id,
                dicomNodeId: dicomNode,
            });
        }
    };

    return (
        <Modal
            footer={[
                <Button key="cancel" onClick={onCancel}>
                    Cancel
                </Button>,
                <Button className="bg-blue-500 hover:bg-blue-700 text-white" key="ok" type="primary" onClick={handleOk}>
                    OK
                </Button>,
            ]}
            width={800}
            onCancel={() => onCancel()}
            visible={true}
        >
            <h3 className='font-bold'>Gateway is a way out of your local network hospital</h3>
            <div className='mt-2.5'>
                <SearchGatewayFilter onSearchClick={onSearchGateway} />
            </div>
            <Spin spinning={isLoadingGateway}>
                <Table
                    columns={gatewayColumns}
                    dataSource={gatewayData}
                    rowKey={"id"}
                    rowSelection={{
                        type: 'radio',
                        onChange: handleGatewaySelect,
                        selectedRowKeys: gateway ? [gateway.id] : [],
                    }}
                    pagination={false}
                />
            </Spin>

            <h3 className='font-bold' style={{ marginTop: 20 }}>DICOM Node is a PACS/Scanner connected via the Gateway</h3>
            <Spin spinning={isLoadingDicom && (!dicomNodes || dicomNodes.length === 0)}>
                <Table
                    dataSource={dicomNodes}
                    columns={dicomNodeColumns}
                    rowKey={"id"}
                    rowSelection={{
                        type: 'radio',
                        onChange: handleDicomSelect,
                        selectedRowKeys: dicomNode ? [dicomNode] : [],
                    }}
                    pagination={false}
                />
            </Spin>
        </Modal>
    );
};

export default SelectGatewayAndDicomModal;