import { io } from "socket.io-client";

let socket = null;
export const initSocket = (socketUrl, token, conversationId) => {
    if (socket && socket.connected) {
        console.warn('Socket is already connected or in the process of connecting.')
        return socket
    }

    socket = io(socketUrl, {
        autoConnect: true,
        reconnection: true,
        auth: {
            jwt: `Bearer ${token}`,
            ...(conversationId ? { conversation_id: conversationId } : {}),
        },
    });
    
    return socket
}