import React, { useEffect } from 'react';
import { Modal, Space } from 'antd';
import { Tabs } from 'antd';
import ClinicalReport from './Reports/ClinicalReport'
import Conversation from './Reports/Converstation'


const ReportModalV2 = ({ studyId, studyLink, isOpen, closable = true, onClose, isOwner = true }) => {
    const tabs = [
        {
            key: 'report-tabs-01',
            label: 'Clinical Notes & Report',
            children: <ClinicalReport
                studyLink={studyLink}
                isOwner={isOwner}
                closable={closable}
                onClose={onClose}
                studyId={studyId} />
        },
        {
            key: 'report-tabs-02',
            label: 'Chat',
            children: <Conversation studyId={studyId} />
        },
    ];

    const onChange = (key) => {
        console.log(key);
    };

    useEffect(() => {
        if (!studyLink || studyLink.length === 0) return;
        window.open(studyLink);
    }, []);

    return (
        <Modal
            title={
                <div className="flex">
                    <span className='mt-0.5'>Clinical Reports</span>
                </div>
            } open={isOpen}
            onCancel={onClose}
            width={1000}
            closable={closable}
            footer={null}
        >
            <Space direction="vertical" style={{ width: '100%' }} size="large">
                <Tabs defaultActiveKey={tabs[0].key} items={tabs} onChange={onChange} />
            </Space>
        </Modal>
    );
};

export default ReportModalV2;