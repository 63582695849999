import React, { useEffect, useMemo, useState } from "react";
import { Steps, Modal, message } from 'antd';
import { Divider } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Collapse, Button, Radio } from 'antd';
import { Card, Descriptions } from 'antd';
import { Checkbox } from 'antd';
import { findSCUScore } from '../../apis/ai'
import { Spin } from 'antd';
import lodash from 'lodash'
import { Form, Input, Select, Tooltip } from 'antd';
import { hash } from '../../libs/crypt'
import { PlusOutlined } from '@ant-design/icons';

const { Option } = Select;

const ProfileCard = ({
    mode = 'read',
    patientName = '',
    patientID = '',
    birthDate = '',
    gender = '',
    accessionNumber = '',
    score,
    patientDataPosition,
    onPatientDataUpdate = () => { },
}) => {
    // For edit mode, we keep local state of form values
    const [formValues, setFormValues] = useState({
        patientName,
        patientID,
        birthDate,
        gender,
        accessionNumber,
    });
    const [form] = Form.useForm();
    useEffect(() => {
        form.setFieldsValue({
            patientName,
            patientID,
            birthDate,
            gender,
            accessionNumber,
            score,
        });
    }, [patientName, patientID, gender, birthDate, accessionNumber, score]);

    const handleFormChange = (changedValues, allValues) => {
        setFormValues(allValues);
        onPatientDataUpdate(allValues, patientDataPosition);
    };

    if (mode === 'read') {
        return (
            <Card>
                <Descriptions column={1} bordered size="small">
                    <Descriptions.Item label="Patient Name">
                        {patientName || 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Patient ID">
                        {patientID || 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label="DOB">
                        {birthDate || 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Gender">
                        {gender || 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Accession Number">
                        {accessionNumber || 'N/A'}
                    </Descriptions.Item>
                </Descriptions>
            </Card>
        );
    }

    return (
        <Card>
            <Form
                layout="vertical"
                initialValues={formValues}
                form={form}
                onValuesChange={handleFormChange}
            >
                <Form.Item label="Patient Name" name="patientName">
                    <Input placeholder="Enter Patient Name" />
                </Form.Item>

                <Form.Item label="Patient ID" name="patientID">
                    <Input placeholder="Enter Patient ID" />
                </Form.Item>

                <Form.Item label="Birth Date" name="birthDate">
                    <Input placeholder="Enter Birth Date" />
                </Form.Item>

                <Form.Item label="Gender" name="gender">
                    <Select placeholder="Select Gender">
                        <Option value="M">Male</Option>
                        <Option value="F">Female</Option>
                        <Option value="O">Other</Option>
                    </Select>
                </Form.Item>

                <Form.Item label="Accession Number" name="accessionNumber">
                    <Input placeholder="Enter Accession Number" />
                </Form.Item>

                <Form.Item name="score" noStyle>
                    <Input type="hidden" />
                </Form.Item>
            </Form>
        </Card>
    );
};
export const TransferConfirmation = ({ sourceGateway, destinationGateway, sourceStudyData, onClose, onStudyConfirm, sendWithAnonymous }) => {
    const [transferStudyData, setTransferStudyData] = useState([]);
    const [currentSelectedSourceData, setSelectedSourceData] = useState(sourceStudyData[0]);
    const [currentTab, setCurrentTab] = useState(0);
    const [selectedDestinationStudyDataPosition, setDestinationStudyData] = useState(-1);
    const [shouldCreateNewPatient, setShouldCreateNewPatient] = useState(false);
    const [isLoadingPatientData, setIsLoadingPatientData] = useState(false);
    const [selectedRadio, setSelectedRadio] = useState(null);
    const isSentToCloudPacs = destinationGateway.id === 'cloud-pacs';


    const createStudyDataFromSource = (currentSelectedSourceData, isAnonymous = false) => {
        if (!currentSelectedSourceData) return null;
        const studyUID = currentSelectedSourceData.studyUID;
        return {
            patientName: isAnonymous ? `${sourceGateway.dicomNode.name}${hash(studyUID)}` : currentSelectedSourceData.patientName,
            patientID: isAnonymous ? hash(studyUID) : currentSelectedSourceData.patientID,
            birthDate: currentSelectedSourceData.birthDate,
            gender: currentSelectedSourceData.gender, // Keep gender unchanged
            accessionNumber: isAnonymous ? hash(studyUID) : currentSelectedSourceData.accessionNumber,
        };
    };


    const updatePatientData = (patientData, patientDataPosition) => {
        const newStudyData = [...transferStudyData];
        newStudyData[patientDataPosition] = patientData;
        setTransferStudyData(newStudyData);
    }

    function createStudyCollection(studyData) {
        return studyData.map((patientData, index) => {
            return {
                key: `transfer-confirmation-${index}`,
                label: (
                    <div className="flex flex-row items-center justify-between w-full">
                        <div className="flex-1 min-w-0"> {/* Removed border */}
                            <Tooltip title={`${patientData.patientName}${patientData.score ? ` - ${patientData.score}` : ''}`}>
                                <div className="flex items-center justify-between w-full pr-4">
                                    <p className="overflow-hidden whitespace-nowrap text-ellipsis max-w-[200px]">
                                        {patientData.patientName}
                                    </p>
                                    {patientData.score && (
                                        <p className="flex-shrink-0 text-gray-500">
                                            Confident: {patientData.score}
                                        </p>
                                    )}
                                </div>
                            </Tooltip>
                        </div>
                        {!shouldCreateNewPatient && (
                            <div className="flex-shrink-0"> {/* Prevent Radio from shrinking */}
                                <Radio className="-mr-3" value={index}>
                                    Merge
                                </Radio>
                            </div>
                        )}
                    </div>
                ),
                children: (
                    <div>
                        <ProfileCard
                            {...patientData}
                            onPatientDataUpdate={updatePatientData}
                            patientDataPosition={index}
                            mode={'edit'}
                            shouldCreateNewPatient={shouldCreateNewPatient}
                        />
                    </div>
                ),
            };
        });
    }

    const createStepItems = (studyData) => {
        return studyData.map((patientData, index) => {
            return {
                key: `transfer-step-${index}`,
                title: <Tooltip title={patientData.patientName}>
                    <span
                        className="
                    inline-block
                    w-[200px]
                    whitespace-nowrap
                    overflow-hidden
                    text-ellipsis
                  "
                    >
                        {patientData.patientName}
                    </span>
                </Tooltip>,
                description: patientData.patientID || 'N/A',
            };
        });
    }

    const stepStudyItem = createStepItems(sourceStudyData);
    const transferStudyCollection = createStudyCollection(transferStudyData);

    const fetchSCUScore = async () => {
        try {
            if (!currentSelectedSourceData || sendWithAnonymous || isSentToCloudPacs) return;
            setIsLoadingPatientData(true);
            const patientName = lodash.get(currentSelectedSourceData, "patientName", "")
            const patientID = lodash.get(currentSelectedSourceData, "patientID", "")
            const gender = lodash.get(currentSelectedSourceData, "gender", "")
            const location = lodash.get(currentSelectedSourceData, "location", "")
            let tags = [
                {
                    "key": "00100010",
                    "value": patientName,
                },
                {
                    "key": "00100020",
                    "value": patientID,
                },
                {
                    "key": "00100040",
                    "value": gender,
                },
                {
                    "key": "00101040",
                    "value": location
                },
                {
                    "key": "00080052",
                    "value": "STUDY"
                }
            ];

            const response = await findSCUScore({
                gateway_id: destinationGateway.id,
                node_id: destinationGateway.dicomNode.id,
                tags,
            });

            setIsLoadingPatientData(false);
            if (response.data && response.data.length > 0) {
                setTransferStudyData(response.data);
            }
        } catch (error) {
            console.error("Error findSCUScore", error);
            message.error("Error findSCUScore");
        }
    }

    const onTransferClick = () => {
        if (selectedDestinationStudyDataPosition === -1) {
            message.error("Please select destination study data or create new study");
            return;
        }

        setTransferStudyData([]);
        setCurrentTab(currentTab + 1);
        setSelectedSourceData(sourceStudyData[currentTab + 1]);
        onStudyConfirm(currentSelectedSourceData, transferStudyData[selectedDestinationStudyDataPosition]);
        // 
        setSelectedRadio(false);
        if (sendWithAnonymous || (isSentToCloudPacs && !sendWithAnonymous)) {
            // auto select next patient because create study data disabled
            setStudyDataFromSource(sourceStudyData[currentTab + 1], sendWithAnonymous);
        } else {
            setShouldCreateNewPatient(false);
            setDestinationStudyData(-1);
        }
        // check if already complete if complete will close it
        if (currentTab + 1 > sourceStudyData.length - 1) {
            onClose();
            return;
        }
    }

    const onProfileSelect = (selectedProfile) => {
        setDestinationStudyData(selectedProfile.target.value);
        setSelectedRadio(selectedProfile.target.value);
        //
        setShouldCreateNewPatient(false);
    }

    const setStudyDataFromSource = (currentSelectedSourceData, isAnonymous) => {
        const data = createStudyDataFromSource(currentSelectedSourceData, isAnonymous);
        setTransferStudyData([data]);
        // cause only 1 item in the list
        setDestinationStudyData(0);
    };

    const onCreateSourceDataChange = (e) => {
        setShouldCreateNewPatient(e.target.checked);
        setSelectedRadio(null);
        setDestinationStudyData(-1);

        if (e.target.checked) {
            setStudyDataFromSource(currentSelectedSourceData);
        } else {
            fetchSCUScore();
        }
    }

    const onCancelClick = () => {
        onClose();
    }

    useMemo(() => {
        fetchSCUScore();
    }, [currentSelectedSourceData])

    useEffect(() => {
        if (sendWithAnonymous || (isSentToCloudPacs && !sendWithAnonymous)) {
            setShouldCreateNewPatient(true);
            setStudyDataFromSource(currentSelectedSourceData, sendWithAnonymous);
        }
    }, []);

    const onCreateAllClick = async () => {
        const createdNewStudyData = sourceStudyData.map((studyData) => studyData)
        for (let i = 0; i < createdNewStudyData.length; i++) {
            const newData = createStudyDataFromSource(createdNewStudyData[i], sendWithAnonymous);
            await onStudyConfirm(createdNewStudyData[i], newData);
        }
        onClose();
        message.info("All study data created");
    }

    return (
        <Modal
            open={true}
            closable={true}
            footer={null}
            width={900}
            style={{ top: 50 }}
            onCancel={onClose}
        >
            <div className="flex flex-row justify-between mb-3">
                <div className="flex flex-row">
                    <InfoCircleOutlined className="mb-2.5" style={{ fontSize: '24px' }} />
                    <p className="text-lg font-bold ml-2.5">Confirm Transfer</p>
                </div>
                <Button onClick={onCreateAllClick} className="mr-5" icon={<PlusOutlined />}>Create New For All</Button>
            </div>
            <Steps
                current={currentTab}
                items={stepStudyItem}
            />
            <Divider />
            <div>
                <div className="flex">
                    <InfoCircleOutlined className="mb-2.5 mr-2.5" style={{ fontSize: '24px' }} />
                    <p className="text-lg font-bold">Transfering information</p>
                </div>
                <p className="text-sm text-secondary">
                    We found the same patients at the Destination Gateway. Please select the same patient if the information is compatible or create a new profile for this patient
                </p>
                <div className="flex mt-3 min-h-[380px]">
                    <div className="w-1/2 mr-2.5">
                        <div className="flex items-center justify-between">
                            <p className="mb-2.5">Source gateway</p>
                        </div>
                        {currentSelectedSourceData && (
                            <ProfileCard {...currentSelectedSourceData} />
                        )}
                    </div>
                    <div className="w-1/2">
                        <div className="flex items-center justify-between">
                            <p>Destination gateway</p>
                            <Checkbox disabled={sendWithAnonymous || isSentToCloudPacs} checked={shouldCreateNewPatient} className="mb-2.5" onChange={onCreateSourceDataChange}>Create study data</Checkbox>
                        </div>
                        {transferStudyCollection.length > 0 && (
                            <div className="w-full max-h-[300px] overflow-y-scroll">
                                <Radio.Group value={selectedRadio} onChange={onProfileSelect} className="w-full">
                                    {isLoadingPatientData && <Spin />}
                                    {!isLoadingPatientData && <Collapse items={transferStudyCollection} defaultActiveKey={
                                        (isSentToCloudPacs || sendWithAnonymous) ? [`transfer-confirmation-0`] : []
                                    } />}
                                </Radio.Group>
                            </div>)}
                    </div>
                </div>
            </div>
            <div className="w-full">
                <div className="flex flex-row justify-end">
                    <Button onClick={onCancelClick} className="w-1/2 mr-2.5">Cancel</Button>
                    <Button onClick={onTransferClick} type="primary" style={{ backgroundColor: '#1890ff', borderColor: '#1890ff' }} className="w-1/2">
                        Confirm & Transfer
                    </Button>
                </div>
            </div>
        </Modal>
    );
};
