import { useState, useRef, useCallback, useMemo } from "react";
import { initSocket } from './socket';

export const recordingStatus = 'recording'
export const idleStatus = 'idle'
export const connectingStatus = 'connecting'

export default function useAudioTranscription(socketUrl) {
  const [partialTranscript, setPartialTranscript] = useState("");
  const [finalTranscript, setFinalTranscript] = useState("");
  // ---- ADD: status and error state
  const [status, setStatus] = useState(idleStatus);  // 'idle', 'connecting', 'recording', 'stopped', 'error'
  const [error, setError] = useState(null);

  const socketRef = useRef(null);
  const audioContextRef = useRef(null);
  const processorRef = useRef(null);
  const sourceRef = useRef(null);
  const streamRef = useRef(null);

  // Convert Float32 samples to 16-bit PCM
  const convertFloat32ToPCM = useCallback((float32Buffer) => {
    const output = new Int16Array(float32Buffer.length);
    for (let i = 0; i < float32Buffer.length; i++) {
      let s = Math.max(-1, Math.min(1, float32Buffer[i]));
      output[i] = s < 0 ? s * 0x8000 : s * 0x7FFF;
      output[i] = Math.max(-32768, Math.min(32767, output[i])); // to be extra safe
    }
    return new Uint8Array(output.buffer);
  }, []);

  const startRecording = useCallback(async (token) => {
    try {
      setStatus(connectingStatus);
      setError(null);

      // 1) Create/connect Socket.io if not already connected
      if (!socketRef.current || socketRef.current.disconnected) {
        socketRef.current = initSocket(socketUrl, token);

        // When socket connects successfully:
        socketRef.current.on("connect", () => {
          console.log("Socket connected:", socketRef.current.id);
          setStatus(recordingStatus);
        });

        // Handle socket errors (optional)
        socketRef.current.on("connect_error", (err) => {
          console.error("Connection error:", err);
          setStatus("error");
          setError(err.message);
        });

        // Transcript listeners
        socketRef.current.on("transcript", (data) => {
          const { transcript, isPartial } = data;
          if (isPartial) {
            setPartialTranscript(transcript);
          } else {
            setPartialTranscript("");
            setFinalTranscript((prev) => prev + transcript + " ");
          }
        });

        // Finally, connect the socket
        socketRef.current.connect();
      }

      // 2) Access user mic
      streamRef.current = await navigator.mediaDevices.getUserMedia({ audio: true });

      // 3) Create AudioContext & connect stream
      audioContextRef.current = new AudioContext({ sampleRate: 16000 });
      sourceRef.current = audioContextRef.current.createMediaStreamSource(streamRef.current);

      // 4) Create processor node
      processorRef.current = audioContextRef.current.createScriptProcessor(1024, 1, 1);
      processorRef.current.onaudioprocess = (e) => {
        const inputData = e.inputBuffer.getChannelData(0);
        const pcmBuffer = convertFloat32ToPCM(inputData);
        if (socketRef.current && socketRef.current.connected) {
          socketRef.current.emit("audio", pcmBuffer);
        }
      };

      // 5) Connect the audio pipeline
      sourceRef.current.connect(processorRef.current);
      processorRef.current.connect(audioContextRef.current.destination);

      // Reset transcripts
      setPartialTranscript("");
      setFinalTranscript("");

      // 6) Signal the server to start AWS Transcribe streaming
      socketRef.current.emit("start_transcription");

    } catch (err) {
      console.error("Error accessing microphone:", err);
      setStatus("error");
      setError(err.message);
    }
  }, [socketUrl, convertFloat32ToPCM]);

  const stopRecording = useCallback(() => {
    // 1) Stop mic streams and audio nodes
    if (processorRef.current && sourceRef.current) {
      sourceRef.current.disconnect(processorRef.current);
      processorRef.current.disconnect(audioContextRef.current.destination);
    }
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
    }

    // 2) Tell server to stop transcription
    if (socketRef.current) {
      socketRef.current.emit("stop_transcription");
      socketRef.current.close();
    }

    // Update status back to idle
    setStatus(idleStatus);
  }, []);

  // Return only a new object if state or these callbacks change
  const exports = useMemo(
    () => ({
      partialTranscript,
      finalTranscript,
      setFinalTranscript,
      startRecording,
      stopRecording,
      status,  // expose status
      error,   // expose error
    }),
    [
      partialTranscript,
      finalTranscript,
      setFinalTranscript,
      startRecording,
      stopRecording,
      status,
      error
    ]
  );

  return exports;
}
