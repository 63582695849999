import React, { useEffect, useState, useRef } from 'react';
import ChatMessage from './ChatMessage';
import { Button } from 'antd'
import { SendOutlined } from "@ant-design/icons";
import { useConversationSocket } from '../../../libs/chat'
import { getToken } from "../../../libs/jwt";
import { appConfig } from "../../../config/config";
import { useSearchParams } from 'react-router-dom';
import { queryStudyShareDetail, generateShareLink } from '../../../apis/apis';
import moment from 'moment'

function ChatWindow({ studyId }) {
  const [searchParams] = useSearchParams();
  const [studyData, setStudyData] = useState(null);
  const shareToken = searchParams.get('token');
  const messagesRef = useRef(null);

  const {
    conversation,
    init,
    status,
    postConversation,
  } = useConversationSocket(appConfig.gatewayRealtime)
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchStudyData = async () => {
      try {
        const studyShareDetail = await queryStudyShareDetail(studyId, shareToken);
        if (studyShareDetail.data) {
          setStudyData(studyShareDetail.data);
        }
      } catch(e) {
        if (e.response.status === 401) {
          // share link hasn't generated yet
          await generateShareLink(studyId)
          await fetchStudyData();
        } else {
          console.error(e);
        }
      }
    }

    fetchStudyData();
  }, []);

  useEffect(() => {
    const initChat = async () => {
      if (studyData && studyData.token) {
        init(studyData.token, getToken());
      }
    }

    initChat();
  }, [studyData]);

  const handleSendMessage = () => {
    if (status === 'connected' && message.length > 0) {
      postConversation(message);
      setMessage('');
    }
  }

  const onMessageChange = (e) => {
    setMessage(e.target.value);
  }

  useEffect(() => {
    if (messagesRef.current) {
      messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
    }
  }, [conversation]);

  return (
    <div className="h-screen w-full flex flex-col bg-white max-h-96">
      <div ref={messagesRef} className="flex-grow p-4 overflow-y-auto">
        {conversation.map(conv => (
          <ChatMessage
            key={conv.id}
            content={conv.msg}
            timestamp={moment(conv.updatedAt).format("YYYY-MM-DD HH:mm:ss")}
          />
        ))}
      </div>
      <div className="border-t py-1 flex">
        <input
          className="w-full border rounded px-3 py-2 focus:outline-none"
          placeholder="Type a message..."
          value={message}
          onKeyDown={(e) => { if (e.key === 'Enter') handleSendMessage(); }}
          onChange={(e) => onMessageChange(e)}
        />
        <Button onClick={handleSendMessage} className='mt-1 ml-2.5 border-none' icon={<SendOutlined />} ></Button>
      </div>
    </div>
  );
}

export default ChatWindow;
