import { message } from 'antd'
import { useEffect, useState, useContext } from 'react';
import { Worklist } from './Worklist'
import { AppContext } from '../../../libs/context';
import { GetWorklistByGatewayID, breakGlass } from '../../../apis/ai'

export const GatewayWorkList = ({ onViewLog, onBreakclassClick }) => {
    const [worklist, setWorklist] = useState([]);
    const { store } = useContext(AppContext)
    const { getSelectedWorklistGatewayId } = store;
    const selectedGatewayId = getSelectedWorklistGatewayId();
    useEffect(() => {
        const fetchData = async () => {
            if (!selectedGatewayId) return;
            const response = await GetWorklistByGatewayID(selectedGatewayId);
            if (response.data) {
                setWorklist(response.data);
            }
        };

        fetchData();
    }, [selectedGatewayId])

    const onClickBreakClass = async (record) => {
        try {
            const response = await breakGlass(record.id);
            if (response.data) {
                onBreakclassClick(response.data);
            }
        } catch (e) {
            console.error(e);
            message.error("Error while breaking glass");
        }
    }

    return <div><Worklist allowResend={true} requiredSelectGateway={true} worklist={worklist} onBreakclassClick={onClickBreakClass} onViewLog={onViewLog} /></div>
};
