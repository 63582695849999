import React, { useState } from 'react';
import { Table, Tag, Button, Modal, Tooltip } from 'antd';
import { getJobsDetail } from '../../../apis/job-status';
import { AppContext } from '../../../libs/context'
import { useContext } from 'react'

const defaultPageSize = 15;
const JobTable = ({
  showRowSelection = true,
  jobs = []
}) => {
  const [logModalVisible, setLogModalVisible] = useState(false);
  const [currentLog, setCurrentLog] = useState(null);
  const { store } = useContext(AppContext)
  const { selectedJobs, addSelectedJob, removeSelectedJob } = store;

  const addKeyRecursively = (node) => {
    addSelectedJob(node.id);
    if (node.children) {
      node.children.forEach((child) => addKeyRecursively(child));
    }
  };

  const removeKeyRecursively = (node) => {
    removeSelectedJob(node.id);
    if (node.children) {
      node.children.forEach((child) => removeKeyRecursively(child));
    }
  };

  const selectedRowKeys = Array.from(selectedJobs);
  const rowSelection = {
    selectedRowKeys,
    onSelect: (record, selected, selectedRows) => {
      if (selected) {
        addKeyRecursively(record);
      } else {
        removeKeyRecursively(record);
      }
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      if (selected) {
        changeRows.forEach((item) => addKeyRecursively(item));
      } else {
        changeRows.forEach((item) => removeKeyRecursively(item));
      }
    },
  };


  const onViewJobDetailLog = async (jobId) => {
    try {
      if (!jobId) {
        return;
      }
      const jobResponse = await getJobsDetail(jobId);
      const msg = jobResponse.data?.[0]?.msg || 'No logs found';
      setCurrentLog(msg);
      setLogModalVisible(true);
    } catch (error) {
      console.error(error);
      setCurrentLog(`No logs found`);
      setLogModalVisible(true);
    }
  }

  const columns = [
    {
      title: 'Patient Name',
      dataIndex: 'patientName',
      key: 'patientName',
      render: (_, record) => {
        return (
          <Tooltip key={record.id} placement="left" title={record.patientName}>
            <p className='truncate w-[100px]'>{record.patientName}</p>
          </Tooltip>
        )
      },
    },
    {
      title: 'Patient ID',
      dataIndex: 'patientID',
      key: 'patientID',
      render: (_, record) => {
        return (
          <Tooltip key={record.id} placement="left" title={record.patientID}>
            <p className='truncate w-[100px]'>{record.patientID}</p>
          </Tooltip>
        )
      }
    },
    {
      title: 'Study Description',
      dataIndex: 'studyDescription',
      key: 'studyDescription',
    },
  ];

  const expandedRowRender = (record) => {
    const nestedColumns = [
      {
        title: 'Job name',
        dataIndex: 'name',
        key: 'name',
        render: (_, record) => {
          return <Tooltip key={record.id} placement="left" title={record.name}>
            <p className='truncate w-[150px]'>{record.name}</p>
          </Tooltip>
        }
      },
      {
        title: 'View Log',
        dataIndex: 'viewlog',
        key: 'viewlog',
        render: (text, record) => {
          return (
            <Button
              className="bg-blue-500 hover:bg-blue-700"
              type="primary"
              onClick={() => {
                onViewJobDetailLog(record.id)
              }}
            >
              View Logs
            </Button>
          )
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (status) => {
          if (status.includes('failure')) {
            return <Tag color={'red'}>{status}</Tag>;
          } else {
            return <p>{status}</p>
          }
        },
      },
      {
        title: 'Created At',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (_, record) => {
          return (
            <Tooltip key={record.id} placement="left" title={record.createdAt}>
              <p className='truncate w-[100px]'>{record.createdAt}</p>
            </Tooltip>
          )

        }
      },
    ];

    return (
      <Table
        rowKey="key"
        columns={nestedColumns}
        dataSource={record.jobs.map(job => ({ ...job, key: job.id }))}
        pagination={false}
        rowSelection={showRowSelection ? rowSelection : null}
      />
    );
  };

  return (
    <div>
      {logModalVisible && <Modal
        title="Job Logs"
        visible={logModalVisible}
        onCancel={() => setLogModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setLogModalVisible(false)}>
            Close
          </Button>,
        ]}
      >
        <p>{currentLog}</p>
      </Modal>}
      <Table
        className='w-[full] border border-gray-300'
        columns={columns}
        expandable={{
          expandedRowRender,
        }}
        pagination={{
          pageSize: defaultPageSize,
          defaultPageSize: defaultPageSize,
        }}
        dataSource={jobs.map(job => ({ ...job, key: job.id }))}
      />
    </div>
  );
};

export default JobTable;